import React, {useState, useEffect} from 'react';
import ImageIcon from '@material-ui/icons/Image';
import {Paper, Avatar, Typography} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
// styles
import useStyles from "./styles";

const DRAW_LABEL_TYPE = ['FreehandRoi', 'RectangleRoi'];

export const ViewportSidebar = ({ className, viewport, isClassification,
  setActivePathology, setActiveTool, isSubmit, campaignType, addClassification, ...rest }) => {
  
  const classes = useStyles();
  const [selectedHover, setSelectedHover] = useState('');
  const [selctedLabel, setSelctedLabel] = useState('');

  useEffect(() => {(isSubmit) && (setSelctedLabel(''))}, [isSubmit]);

  const handleClassificationClick = (event, classification) => {
    if(classification !== null){
      setSelctedLabel(classification)
      setActivePathology(classification)
      if(!isClassification){
        let selectedClassification = viewport.classifications.find(x => x.label === classification)
        if(DRAW_LABEL_TYPE.includes(selectedClassification.type)){
          setActiveTool(selectedClassification.type)
        }else{
          setActiveTool('Zoom')
        }
      }

      const handleEsc = (event) => {
        if (event.keyCode === 27) {
          setActivePathology('')
          setActiveTool('Zoom')
        }
      };
      window.addEventListener('keydown', handleEsc);
    }else{
      setSelctedLabel('')
      setActivePathology('')
      setActiveTool('Zoom')
    }
  };

  const getLabelIcon = (type) => {
    switch(type) {
      case 'FreehandRoi':
        return <img src={'/static/icons/free_form.svg'} height={20} width={20}/>
      case 'RectangleRoi':
        return <img src={'/static/icons/bbox.svg'} height={20} width={20}/>
      case 'NoFinding':
        return <img src={'/static/icons/no_finding.svg'} height={15} width={15}/> 
      case 'BadQuality':
        return <img src={'/static/icons/no_finding.svg'} height={15} width={15}/>
      case 'AddLabel':
        return <img src={'/static/icons/no_finding.svg'} height={15} width={15}/>
      default:
        return <ImageIcon />
    } 
  }

  const getLabelSubHeader = (type) => {
    switch(type) {
      case 'FreehandRoi':
        return <span className={classes.classificationButtonSubLabel}>FREE FORM</span>
      case 'RectangleRoi':
        return <span className={classes.classificationButtonSubLabel}>BOUNDING BOX</span>
      case 'NoFinding':
        return <span className={classes.classificationButtonSubLabel}>Add Tag</span>
      case 'BadQuality':
        return <span className={classes.classificationButtonSubLabel}>Add Tag</span>
      case 'AddLabel':
        return <span className={classes.classificationButtonSubLabel}>Add Tag</span>
      default:
        return <ImageIcon />
    } 
  }

  const getClassLabels = viewport.classifications.map((classification) =>
    <ToggleButton value={classification.label} className={classes.classificationButton} disableFocusRipple
      onMouseEnter={() => setSelectedHover(classification.label)}
      onMouseLeave={() => setSelectedHover('')}
      style={{ 
        backgroundColor: (classification.label === selctedLabel) ? ('rgba(113, 135, 150, 0.4)') : ('white'),
        color: (classification.label === selctedLabel) ? ('white') : 
        ((classification.label === selectedHover) && (classification.color)),
        }} >
        <Avatar 
          style={{ marginRight: '10px', width: '32px', height: '32px',
          backgroundColor: (classification.label === selctedLabel) ? (classification.color) : 
          ((selectedHover === classification.label) ? (classification.color) : ('#25445C')),
          color: (classification.label === selctedLabel) && (classification.color)}}>
          {getLabelIcon(classification.type)}
        </Avatar>
        <span className={classes.classificationButtonLabel}
          style={{color:(classification.label === selctedLabel) ? ('white') : 
            ((selectedHover === classification.label) ? (classification.color) : ('#25445C'))}}>
          {getLabelSubHeader(classification.type)}
          {classification.label}
        </span> 
    </ToggleButton>
  );

  return (
    <Paper square className={classes.sidebarContainer} elevation={0}>
      <Paper square className={classes.sidebarHeader} elevation={0}> 
        <div className={classes.sidebarHeaderAvatarRoot}>
          <Typography variant='subtitle1' className={classes.sidebarHeaderText}>Menu de Validação</Typography>
        </div>
      </Paper>
      <ToggleButtonGroup orientation="vertical" disableRipple
        className={classes.classificationButtonGroup}
        value={selctedLabel} exclusive onChange={handleClassificationClick}>
        {getClassLabels}
      </ToggleButtonGroup>
    </Paper>
  );
};

export default ViewportSidebar;

