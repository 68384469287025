import React, { useState, useEffect } from 'react'
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import { useParams, useHistory } from 'react-router-dom'
import {
  Grid,
  Paper,
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  withStyles
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close'
import ViewportSidebarConfirmation from '../ViewportSidebarConfirmation/ViewportSidebarConfirmation'
import { v4 as uuidv4 } from 'uuid'
import LoadingOverlay from 'react-loading-overlay'
import Loader from 'react-loader-spinner'
import { studyService } from '../../services/studies'
import { userStore } from '../../context/UserContext'
import Annotator from '../Annotator/Annotator'

import { sampleData } from './data'
// styles
import useStyles from './styles'
import { ArrowDropDownCircle } from '@material-ui/icons'
import { useRef } from 'react'
import Header from '../Header/Header'
import { useConfirm } from 'material-ui-confirm'
import { Alert } from '@mui/material'
import Hotkeys from 'react-hot-keys';

export const Ner = ({ className, ...rest }) => {
  const classes = useStyles()

  let { id } = useParams()
  let history = useHistory()
  const userBilling = userStore.getState().userBilling
  const viewRef = useRef(null)
  const [resultsArray, setResultsArray] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const [studyId, setStudyId] = useState('')
  const [nextStudyId, setNextStudyId] = useState('')
  const [nextStudyDetails, setNextStudyDetails] = useState('')
  const [studyDetails, setStudyDetails] = useState(sampleData)
  const [activePart, setActivePart] = useState('')

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip); 

  useEffect(() => {
    if (isSubmit) {
      setIsLoaded(false)
      if (nextStudyId) setStudyId(nextStudyId)
      setNextStudyId('')
      setResultsArray([])
    }
    if (!isSubmit && nextStudyId !== studyId) {
      setStudyId(nextStudyId)
      setNextStudyDetails([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit, nextStudyId, studyId])
  const [tag, setTag] = useState(null)

  useEffect(() => {
    if (studyId === '') {
      studyService
        .fetchNextExamId('nlp', id)
        .then(response => {
          if (response === undefined) {
            history.push('/app/dashboard')
          }
          setStudyId(response[0].data.id)
          setStudyDetails(response[0])
          setTag(response[0].data?.pathology?.pathology_list[0])
          setActivePart(response[0].data.body_part)
          if (response.length >= 1) {
            setNextStudyId(response[0].data.id)
            setNextStudyDetails(response[0])
          } else {
            setNextStudyId('')
            setNextStudyDetails([])
          }
          setIsLoaded(true)
        })
        .catch(err => {
          history.push('/app/dashboard')
        })
    }
  }, [history, id, studyId])

  const addClassification = pathology => {
    if (resultsArray.some(result => result.classification === pathology)) return
    let newResultsArray = pathology.map(annotation => {
      return {
        id: uuidv4(),
        classification: annotation.tag,
        doc_details_id: studyId,
        pathology_info_id: id,
        body_part: activePart,
        ner_result: annotation
      }
    })
    setResultsArray(newResultsArray)
  }

  const [open, setOpen] = useState(true)
  const [contextTags, setContextTags] = useState([])
  const [state, setState] = useState([])
  const confirm = useConfirm()
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x).toLowerCase());
  const numb = [...Array(10).keys()]
  const hotKeysNlpList = alphabet.concat(numb).toString()

  function activateFromHotKey(id) {
    studyDetails.data.pathology.pathology_list.map( tag_ => {
      if (tag_.shortcut == id && tag_.sublabel== undefined){
        setTag(tag_)
        setContextTags(prevState => [
          ...prevState.map(i => ({
            ...i,
            active: false
          })),
          {
            id: tag_.id,
            labels: tag_.label,
            active: true
          }
        ])
      }
    })
    }

  return (
    <Hotkeys keyName={hotKeysNlpList} onKeyDown={(event) => activateFromHotKey(event)}>
    <LoadingOverlay
      active={!isLoaded}
      fadeSpeed={0}
      styles={{
        overlay: base => ({ ...base, background: '#fff', minHeight: '100vh' })
      }}
      spinner={<Loader type="Bars" color="#00BFFF" height={100} width={100} />}
    >
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity="error">{alertText}</Alert>
      </Snackbar>
      <Snackbar
        open={showAlertSuccess}
        autoHideDuration={3000}
        onClose={() => setShowAlertSuccess(false)}
      >
        <Alert severity="success">
          {'Informações atualizadas com sucesso'}
        </Alert>
      </Snackbar>
      <Header
        totalEarning={userBilling.totalEarning}
        nextPayment={userBilling.nextPayment}
      />
      <Grid
        container
        style={{
          height: '100%',
          minHeight: 'calc(100vh - 38px)',
          marginTop: '38px'
        }}
      >
        <Grid item xs={12}>
          <Paper square style={{ height: '100%' }}>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="space-between"
              style={{ height: '100%' }}
            >
              <Grid
                item
                xs={open ? studyDetails.data?.pathology?.pathology_list[0].sublabel ? 6 : 8 : 12}
                style={{
                  width: '100%',
                  height: '100%',
                  ...(!open && { paddingRight: '35px' })
                }}
              >
                <Paper elevation={0}>
                  <Grid container className={classes.toolsMenuContainer}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ width: '100%' }}
                    >
                      <Box style={{ marginLeft: '10px' }}>
                        <div className="font-normal leading-tight mt-5 mb-8">
                          <strong>Resumo</strong> - Selecione uma parte do texto
                          para fazer uma marcação
                        </div>
                      </Box>
                      <Box style={{marginLeft: "auto", paddingRight: 10, color:'#214459'}} >
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">Teclas rápidas</Typography>
                              <p></p>
                              <b>{"shift + e"}</b> {'=>'} {'Submit Feedback'}{' '}<p></p>
                              <b>{"shift + space"}</b> {'=>'} {'SKIP'}{' '}<p></p>
                            </React.Fragment>
                          }>
                          <AssignmentLate />
                        </HtmlTooltip>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid style={{ width: '100%' }} direction="column">
                    <Grid item xs={12}>
                      <Box>
                        {isLoaded && (
                          <Annotator
                            state={[state, setState]}
                            contextTagsState={[contextTags, setContextTags]}
                            resultsArray={resultsArray}
                            addClassification={addClassification}
                            studyDetails={studyDetails}
                            tag={[tag, setTag]}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={0}>
                  <Box p={1}>
                    <h3 className="text-center font-semibold text-[20px] mt-4 mb-5">
                      Avaliações de Exame
                    </h3>
                    <Hotkeys
                        keyName="shift+e,shift+space"
                        onKeyDown={event => {
                          if (event === 'shift+e'){
                            viewRef.current && viewRef.current.submit()
                            setContextTags([])
                            setState([])
                            setShowAlertSuccess(true)
                          }
                          else{
                            confirm({
                              title: 'Pular Avaliação',
                              description:
                                'Você está pulando esta avaliação, realmente deseja fazer isso?',
                              cancellationText: 'Cancelar',
                              confirmationText: 'Confirmar'
                            }).then(() => {
                              viewRef.current && viewRef.current.skip()
                              setContextTags([])
                              setState([])
                            })
                          } 
                        }}
                      >
                    <Card square={true} elevation={3} variant="elevation">
                      <h3 className="text-center font-norma text-[20px] mt-4 mb-4">
                        NLP EXAME
                      </h3>
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="pb-6"
                          gridGap="15px"
                        >
                          <button
                            className={classes.button}
                            onClick={() => {
                              confirm({
                                title: 'Pular Avaliação',
                                description:
                                  'Você está pulando esta avaliação, realmente deseja fazer isso?',
                                cancellationText: 'Cancelar',
                                confirmationText: 'Confirmar'
                              }).then(() => {
                                viewRef.current && viewRef.current.skip()
                                setContextTags([])
                                setState([])
                              })
                            }}
                          >
                            Pular
                          </button>
                          <button
                            className={classes.button}
                            disabled={state.length === 0}
                            onClick={() => {
                              viewRef.current && viewRef.current.submit()
                              setContextTags([])
                              setState([])
                              setShowAlertSuccess(true)
                            }}
                          >
                            Próximo
                          </button>
                          <button
                        className={classes.button}
                        onClick={() => {
                          confirm({
                            title: 'Informação insuficiente',
                            description:
                              'Deseja confirmar a insuficiência de informação e passar para o próximo?',
                            cancellationText: 'Cancelar',
                            confirmationText: 'Confirmar'
                          }).then(() => {
                            viewRef.current && viewRef.current.noInfo()
                            setContextTags([])
                            setState([])
                          })
                        }}
                        >
                          Sem Info Suficiente
                        </button>
                        </Box>
                      </Box>
                      <Box className="mx-2"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        paddingBottom="10px">
                        <strong>
                          {viewRef.current &&
                            viewRef.current?.activeStep + 1}{' '}
                          de{' '}
                          {
                            nextStudyDetails.data?.pathology
                              ?.exams_in_package
                          }
                        </strong>
                      </Box>
                    </Card>
                    </Hotkeys>
                  </Box>
                </Paper>
              </Grid>
              {open ? (
                <Grid
                  item
                  className="relative"
                  xs={studyDetails.data?.pathology?.pathology_list[0].sublabel ? 6 : 4}
                  style={{ backgroundColor: '#fff', paddingTop: '40px' }}
                >
                  <ArrowDropDownCircle
                    style={{
                      transform: 'rotate(270deg)'
                    }}
                    className="cursor-pointer absolute text-[#1669E5] text-[22px] left-0 top-[28px]"
                    onClick={() => setOpen(prevState => !prevState)}
                  />
                  <Box p={1} style={{ paddingTop: '0px' }}>
                    <Card square={true} elevation={3} variant="elevation">
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          columns={12}
                          className="max-h-[560px] overflow-auto"
                        >
                          {studyDetails.data?.pathology?.pathology_list.map(
                            tag_ => (
                              <Grid
                                item
                                className={`w-full`}
                              >
                                <FormControl fullWidth>
                                  <div
                                    className={`px-3 py-2  rounded-md ${
                                      tag_.sublabel &&
                                      'border-2 border-[#949494] w-full'
                                    } ${!tag_.sublabel && `bg-${tag_.color}`} flex`}
                                  >
                                    {tag_.sublabel ? (
                                      <Select
                                        disableUnderline
                                        style={{
                                          width: 'inherit',
                                          fontSize: '14px'
                                        }}
                                        labelId="tag"
                                        value={tag_.label}
                                        label={tag_.label}
                                        onChange={event => {
                                          setTag(tag_)
                                          setContextTags(prevState => [
                                            ...prevState.map(i => ({
                                              ...i,
                                              active: false
                                            })),
                                            {
                                              id: tag_.id,
                                              labels: event.target.value,
                                              active: true
                                            }
                                          ])
                                        }}
                                      >
                                        <MenuItem
                                          selected
                                          disabled
                                          value={tag_.label}
                                        >
                                          {tag_.label}
                                        </MenuItem>
                                        {tag_.sublabel.map(label => (
                                          <MenuItem
                                            value={label}
                                            disabled={Boolean(
                                              contextTags.find(
                                                item => item.labels === label
                                              )
                                            )}
                                          >
                                            {label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    ) : (
                                      <button
                                        className='text-left flex-grow'
                                        onClick={() => {
                                          setTag(tag_)
                                          setContextTags(prevState => [
                                            ...prevState.map(i => ({
                                              ...i,
                                              active: false
                                            })),
                                            {
                                              id: tag_.id,
                                              labels: tag_.label,
                                              active: true
                                            }
                                          ])
                                        }}
                                      >
                                        {tag_.label} [{tag_.shortcut}]
                                      </button>
                                    )}
                                  </div>
                                  {tag_.sublabel && (
                                    <div className="flex flex-col mt-2">
                                      {contextTags.filter(i => i.id === tag_.id)
                                        .length > 0 &&
                                        contextTags
                                          .filter(i => i.id === tag_.id)
                                          .map(item => (
                                            <button
                                              onClick={event => {
                                                event.stopPropagation()
                                                setTag(tag_)
                                                setContextTags(prevState => [
                                                  ...prevState.map(tagitem => {
                                                    if (
                                                      tagitem.labels !==
                                                      item.labels
                                                    ) {
                                                      return {
                                                        ...tagitem,
                                                        active: false
                                                      }
                                                    }
                                                    return {
                                                      ...tagitem,
                                                      active: true
                                                    }
                                                  })
                                                ])
                                              }}
                                              style={{
                                                ...(!item.active && {
                                                  backgroundColor: 'transparent'
                                                })
                                              }}
                                              className={`border-2 border-${tag_.color} bg-${tag_.color} rounded-xl text-[#000] px-[7px] py-[2px] w-fit mt-[3px] font-normal`}
                                            >
                                              {item.labels}{' '}
                                              <button
                                                onClick={() => {
                                                  confirm({
                                                    title: 'Excluir marcação',
                                                    description:
                                                      'Você está prestes a excluir todas suas marcações que já foram feitas, essa ação não poderá ser desfeita. Tem certeza que deseja fazer isso?',
                                                    cancellationText:
                                                      'Cancelar',
                                                    confirmationText:
                                                      'Confirmar'
                                                  }).then(() => {
                                                    setContextTags(prevState =>
                                                      prevState.filter(
                                                        i =>
                                                          i.labels !==
                                                          item.labels
                                                      )
                                                    )
                                                    setState(prevState =>
                                                      prevState.filter(
                                                        i =>
                                                          i.tag !== item.labels
                                                      )
                                                    )
                                                  })
                                                }}
                                              >
                                                <CloseIcon
                                                  style={{ fontSize: 12 }}
                                                />
                                              </button>
                                            </button>
                                          ))}
                                    </div>
                                  )}
                                </FormControl>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              ) : (
                <button
                  style={{
                    position: 'absolute',
                    top: '130px',
                    right: '-63px',
                    transform: 'rotate(270deg)',
                    background: '#1669E5',
                    color: '#fff',
                    padding: '10px 15px'
                  }}
                  onClick={() => setOpen(true)}
                >
                  marcações realizadas
                </button>
              )}
            </Grid>
          </Paper>
          <ViewportSidebarConfirmation
            type={'ner'}
            ref={viewRef}
            hidden
            nextStudyDetails={nextStudyDetails}
            results={resultsArray}
            studyId={studyId}
            setIsSubmit={setIsSubmit}
            isSubmit={isSubmit}
            setAlertTextNer={setAlertText}
            setShowAlertNer={setShowAlert}
          />
        </Grid>
      </Grid>
    </LoadingOverlay>
    </Hotkeys>
  )
}

export default Ner
