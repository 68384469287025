import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Radio,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup
} from '@mui/material'
import upImg from './assets/up.png'
import downImg from './assets/down.png'
import 'react-tooltip/dist/react-tooltip.css'
import CornerstoneViewport from 'react-cornerstone-viewport'
import cornerstoneTools from 'cornerstone-tools'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import logo from './assets/logo.svg'
import writeIcon from './assets/write.svg'
import { Header } from './components/Header'
import {
  TOOLS_BAR_DATA,
  TOOLS_CORNERSTONE,
  TRANSLATE_CLASSIFICATION_CLASS,
  STATUS,
  VOI_PRESETS,
  SELECT_MARKER
} from './contants.jsx'
import cornerstone from 'cornerstone-core'
import { useStyles } from './styles'

import ClearIcon from '@material-ui/icons/Clear'
import VisibilityIcon from '@material-ui/icons/Visibility'
import {
  getExam,
  getNextExam,
  getTotalExams,
  handleChangeData,
  handleDisableTool,
  handleNextExam,
  handleSkipcampaing,
  handleSubmit
} from './controller'
import { Loading } from '../../components/Loading'
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { ModalTextArea } from './components/ModalTextArea'
import { useHotkeys } from 'react-hotkeys-hook'

export const ModalValidation = () => {
  const {
    state: { project }
  } = useLocation()
  const classes = useStyles()
  const [modalPresence, setModalPresence] = useState({
    open: false,
    position: [],
    id: null
  })
  const [correctLocalComments, setCorrectLocalComments] = useState([])
  cornerstoneTools.toolStyle.setToolWidth(5)
  const [loading, setLoading] = useState(true)
  const [predictions, setPredictions] = useState([])
  const [nextLoading, setNextLoading] = useState(true)
  const [getHandle, setGetHandle] = useState(null)
  const [indexCornerStone, setIndexCornerStone] = useState(0)

  const [
    cornerStoneElementPathology,
    setCornerStoneElementPathology
  ] = useState(null)
  const [toolsBarPathology, setToolsBarPathology] = useState(TOOLS_BAR_DATA)
  const [activeToolPathology, setActiveToolPathology] = useState('Wwwc')
  const [cornerStoneElementDefault, setCornerStoneElementDefault] = useState(
    null
  )
  const [toolsBarDefault, setToolsBarDefault] = useState(TOOLS_BAR_DATA)
  const [activeToolDefault, setActiveToolDefault] = useState('Wwwc')
  const [imagesIDS, setImagesIDS] = useState([])

  const [validationContent, setValidationContent] = useState(null)
  const [selectedPathology, setSelectedPathology] = useState({
    name: '',
    image: []
  })
  const history = useHistory()
  const [roiArray, setRoiArray] = useState([])
  const [activeImage, setActiveImage] = useState(0)
  const [markPathology, setMarkPathology] = useState('')
  const [activePathologies, setActivePathologies] = useState([])
  const [pathologyNothing, setPathologyNothing] = useState([])
  const [total, setTotal] = useState({
    total: 0,
    total_validated: 0
  })
  const [nextData, setNextData] = useState([])
  const [windowingDefaultActive, setWindowingDefaultActive] = useState(null)
  const predictionClasses = predictions.map(prediction => TRANSLATE_CLASSIFICATION_CLASS[prediction.prediction_class]);

  useHotkeys(
    'up',
    () => setIndexCornerStone(count => (count === 0 ? 0 : count - 1)),
    {
      enabled: !Boolean(selectedPathology.name)
    }
  )
  useHotkeys(
    'down',
    () =>
      setIndexCornerStone(count =>
        count === imagesIDS.length - 1 ? imagesIDS.length - 1 : count + 1
      ),
    {
      enabled: !Boolean(selectedPathology.name)
    }
  )
  const scrollCorner = window.document.querySelector('.scroll')
  useEffect(() => {
    try {
          if (scrollCorner && !selectedPathology.name) {
      window.document.querySelector('.scroll').style.display = 'none'
    } else if (scrollCorner && !!selectedPathology.name) {
      window.document.querySelector('.scroll').style.display = 'block'
    }
    }
    catch (e) {
      console.log(e)
      }
  }, [scrollCorner, selectedPathology])

  useEffect(() => {
    ;(async () => {
      const total = await getTotalExams({
        id: project.campaign_id,
        type: project.modality
      })
      setTotal(total)
      const nextResponse = await getNextExam({
        id: project.campaign_id,
        modality: project.modality
      })
      if (nextResponse.data == undefined) {
        history.push('/app/dashboard')
      } else {
        const response = await getExam({
          id: project.campaign_id,
          sop: nextResponse.data[0],
          modality: project.modality
        })

        handleChangeData({
          data: response.data,
          setImagesIDS,
          setValidationContent,
          setPredictions,
          setRoiArray,
          setLoading
        })
        const responseNext = await getExam({
          id: project.campaign_id,
          sop: nextResponse.data[1],
          modality: project.modality
        })
        setNextData(responseNext)
        setNextLoading(false)
      }
    })()
  }, [history, project])

  const handleActiveTool = useCallback(
    ({ name, tool, array, setTool, setActive }) => {
      const allInative = array.map(item => ({
        ...item,
        active: false
      }))
      setTool(allInative.map(item => ({ ...item, active: item.name === name })))
      setActive(tool)
    },
    []
  )

  const handleAddRoi = useCallback(
    event => {
      event.detail.measurementData.handles.textBox.freehand = true
      event.detail.measurementData.color = markPathology.color

      setRoiArray(prevState => {
        return [
          ...prevState,
          {
            exam_id: imagesIDS[activeImage]?.exam_id,
            data: event.detail,
            label: markPathology.label,
            pathology: markPathology.value,
            imageIdIndex: activeImage,
            color: markPathology.color,
            type: 'ROI',
            label_type: markPathology.type,
            serie_instance_uid: validationContent.serie_instance_uid,
            sop_instance_uid: imagesIDS[activeImage].sop_instance_uid,
            study_category: validationContent.study_category,
            study_instance_uid: validationContent.study_instance_uid,
            modality: validationContent.modality,
            roi_points: event.detail.measurementData.handles.points.map(
              item => ({ y: item.y, x: item.x })
            )
          }
        ]
      })
    },
    [
      markPathology.color,
      markPathology.label,
      markPathology.value,
      markPathology.type,
      activeImage,
      validationContent,
      imagesIDS
    ]
  )
  const handleRemoveRoi = useCallback(
    event => {
      const oldRoi = roiArray.filter(
        item =>
          item?.data?.measurementData.uuid !== event.data.measurementData.uuid
      )
      const currentRoi = roiArray.find(
        item =>
          item?.data?.measurementData.uuid === event.data.measurementData.uuid
      )
      setRoiArray(() => [
        ...oldRoi,
        {
          id: currentRoi.id,
          exam_id: currentRoi.exam_id,
          serie_instance_uid: currentRoi.serie_instance_uid,
          sop_instance_uid: currentRoi.sop_instance_uid,
          study_category: currentRoi.study_category,
          study_instance_uid: currentRoi.study_instance_uid,
          modality: currentRoi.modality,
          pathology: ''
        }
      ])

      cornerstoneTools.removeToolState(
        event.data.element,
        event.data.toolName,
        cornerstoneTools
          .getToolState(event.data.element, event.data.toolName)
          .data.filter(item => item.uuid === event.data.measurementData.uuid)[0]
      )

      cornerstone.updateImage(event.data.element)
    },
    [roiArray]
  )

  const handleHighlight = event => {
    const toolState = cornerstoneTools.getToolState(
      event.data.element,
      event.data.toolName
    ).data
    toolState.map(obj => (obj.active = false))
    toolState.filter(
      item => item.uuid === event.data.measurementData.uuid
    )[0].active = true
    cornerstone.updateImage(event.data.element)
  }

  const nextButtonDisabled = ![
    roiArray.filter(item => item.pathology !== '').length === 0,
    !predictions
      .map(item => item.is_present)
      .includes(true)
  ].includes(false)

  const normalButtonDisabled = [
    roiArray.filter(item => item.pathology !== '').length === 0,
    !predictions
      .map(item => item.is_present)
      .includes(true)
  ].includes(false)

  const [openDialogSkip, setOpenDialogSkip] = useState(false)

  useEffect(() => {
    if (getHandle === STATUS.skip && nextLoading === false) {
      handleSkipcampaing({
        setOpenDialogSkip,
        setLoading,
        roiArray,
        predictions,
        project,
        setTotal,
        setMarkPathology,
        nextData,
        setImagesIDS,
        setValidationContent,
        setPredictions,
        setRoiArray,
        setNextData,
        setSelectedPathology,
        setPathologyNothing,
        setNextLoading,
        setCorrectLocalComments
      })
      setGetHandle(null)
    }
    if (getHandle === 'next' && nextLoading === false) {
      handleNextExam({
        setLoading,
        roiArray,
        predictions,
        project,
        setTotal,
        setMarkPathology,
        nextData,
        setImagesIDS,
        setValidationContent,
        setPredictions,
        setRoiArray,
        setNextData,
        setSelectedPathology,
        setPathologyNothing,
        setNextLoading,
        correctLocalComments,
        setCorrectLocalComments
      })
      setGetHandle(null)
    }
  }, [
    nextLoading,
    getHandle,
    nextData,
    predictions,
    roiArray,
    project,
    correctLocalComments
  ])

  return (
    <Container
      disableGutters
      display="flex"
      flex="col"
      sx={{
        minHeight: '100vh',
        backgroundColor: '#000'
      }}
      maxWidth="none"
    >
      {loading && <Loading />}
      {modalPresence.open && (
        <ModalTextArea
          {...modalPresence}
          setModalPresence={setModalPresence}
          commentsState={[correctLocalComments, setCorrectLocalComments]}
        />
      )}

      <Dialog
        open={openDialogSkip}
        onClose={() => setOpenDialogSkip(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deseja realmente pular esta avaliação?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialogSkip(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              setGetHandle(STATUS.skip)
              setLoading(true)
              setWindowingDefaultActive(null)
              setOpenDialogSkip(false)
            }}
            autoFocus
          >
            confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="header"
        p={2}
        bgcolor="#021439"
        display="flex"
        alignItems="center"
        gap="16px"
        sx={{
          height: '83px'
        }}
      >
        <img src={logo} alt="logo" />
        <Typography
          variant="subtitle1"
          color="#fff"
          fontSize="14px"
          fontWeight={400}
        >
          Validação de Modelo {'>'} {project.title}
        </Typography>
      </Box>

      <Grid
        container
        sx={{
          minHeight: 'calc(100vh - 83px)',
          height: '100%'
        }}
      >
        <Grid item xs={7} bgcolor="#fff" display="flex" flexDirection="column">
          <Grid container flex="1">
            <Grid
              item
              xs={selectedPathology.name ? 6 : 12}
              bgcolor="#000"
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                bgcolor="#141414"
                alignItems="center"
                gap="18px"
                sx={{ padding: '10px 15px' }}
                justifyContent="center"
              >
                <ToggleButtonGroup size="small">
                  {VOI_PRESETS.map(voi => (
                    <ToggleButton
                      style={{
                        borderColor: 'white',
                        color:
                          windowingDefaultActive === voi.name
                            ? '#00C1FA'
                            : '#FFFFFF'
                      }}
                      onClick={() => {
                        cornerStoneElementDefault.viewport.voi.windowWidth =
                          voi.windowWidth
                        cornerStoneElementDefault.viewport.voi.windowCenter =
                          voi.windowCenter
                        cornerstone.updateImage(
                          cornerStoneElementDefault.element
                        )
                        setWindowingDefaultActive(voi.name)
                      }}
                    >
                      {voi.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                {toolsBarDefault.map(({ icon, name, active, tool }) => (
                  <button
                    key={name}
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: active ? '#00C1FA' : '#FFFFFF',
                      fontSize: '11px'
                    }}
                    onClick={() => {
                      setMarkPathology('')
                      if (name === 'Inverter') {
                        cornerStoneElementDefault.viewport.invert = !cornerStoneElementDefault
                          .viewport.invert
                        cornerstone.updateImage(
                          cornerStoneElementDefault.element
                        )
                      }
                      if (
                        activeToolDefault === 'Inverter' &&
                        name === 'Inverter'
                      ) {
                        return handleDisableTool({
                          setTool: setToolsBarDefault,
                          setActiveTool: setActiveToolDefault,
                          array: toolsBarDefault
                        })
                      }
                      return handleActiveTool({
                        name,
                        tool,
                        array: toolsBarDefault,
                        setTool: setToolsBarDefault,
                        setActive: setActiveToolDefault
                      })
                    }}
                  >
                    {icon({ size: 15, color: active ? '#00C1FA' : '#FFFFFF' })}
                    {name}
                  </button>
                ))}
              </Box>
              <Box
                flex="1"
                display="block"
                alignItems="center"
                justifyContent="center"
                position="relative"
              >
                {!selectedPathology?.name && imagesIDS.length > 1 && (
                  <>
                    <button
                      className="absolute top-3 right-3 text-[#ffffff] z-50"
                      onClick={() => {
                        setIndexCornerStone(count =>
                          count === 0 ? 0 : count - 1
                        )
                      }}
                    >
                      <img src={upImg} alt="up" />
                    </button>

                    <button
                      className="absolute bottom-3 right-3 text-[#ffffff] z-50"
                      onClick={() => {
                        setIndexCornerStone(count =>
                          count === imagesIDS.length - 1
                            ? imagesIDS.length - 1
                            : count + 1
                        )
                      }}
                    >
                      <img src={downImg} alt="up" />
                    </button>
                  </>
                )}

                {imagesIDS.length > 0 && (
                  <CornerstoneViewport
                    viewportOverlayComponent={() => null}
                    imageIds={imagesIDS.map(item => item.url)}
                    tools={TOOLS_CORNERSTONE}
                    onElementEnabled={elementEnabledEvt => {
                      setCornerStoneElementDefault(elementEnabledEvt.detail)
                    }}
                    imageIdIndex={
                      selectedPathology?.name ? activeImage : indexCornerStone
                    }
                    activeTool={activeToolDefault}
                    style={{ height: '100%', flex: '1' }}
                    isStackPrefetchEnabled
                    eventListeners={[
                      {
                        target: 'cornerstone',
                        eventName: 'cornerstonetoolsmeasurementcompleted',
                        handler: evt => {
                          handleAddRoi(evt)
                        }
                      },
                      {
                        target: 'cornerstone',
                        eventName: 'cornerstoneimageloadfailed',
                        handler: ev => {
                          setImagesIDS(prevState => {
                            if (
                              prevState.find(
                                item => item.url === ev.detail.imageId
                              )
                            ) {
                              return prevState.filter(
                                item => item.url !== ev.detail.imageId
                              )
                            }
                          })
                        }
                      },
                      {
                        target: 'cornerstone',
                        eventName: 'cornerstonetoolsstackprefetchimageloaded',
                        handler: () => {
                          if (
                            Boolean(validationContent) &&
                            windowingDefaultActive === null
                          ) {
                            const voi = VOI_PRESETS.find(
                              item => item.name === validationContent.body_part
                            )
                            cornerStoneElementDefault.viewport.voi.windowWidth =
                              voi.windowWidth
                            cornerStoneElementDefault.viewport.voi.windowCenter =
                              voi.windowCenter
                            cornerstone.updateImage(
                              cornerStoneElementDefault.element
                            )
                            setWindowingDefaultActive(voi.name)
                          }
                        }
                      },
                      {
                        target: 'cornerstone',
                        eventName: 'cornerstonetoolsstackscroll',
                        handler: evt => {
                          setMarkPathology('')
                          setActiveToolDefault('Wwwc')
                          setActiveImage(evt.detail.newImageIdIndex)
                          setIndexCornerStone(evt.detail.newImageIdIndex)
                          handleDisableTool({
                            setTool: setToolsBarDefault,
                            setActiveTool: () => {},
                            array: toolsBarDefault
                          })
                        }
                      }
                    ]}
                  />
                )}
              </Box>
            </Grid>
            {selectedPathology.name && (
              <Grid
                item
                xs={6}
                bgcolor="#000"
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="#141414"
                  gap="18px"
                  sx={{ padding: '10px 15px' }}
                  justifyContent="center"
                >
                  {toolsBarPathology.map(({ icon, name, active, tool }) => (
                    <button
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: active ? '#00C1FA' : '#FFFFFF',
                        fontSize: '11px'
                      }}
                      onClick={() => {
                        setMarkPathology('')
                        if (name === 'Inverter') {
                          cornerStoneElementPathology.viewport.invert = !cornerStoneElementPathology
                            .viewport.invert
                          cornerstone.updateImage(
                            cornerStoneElementPathology.element
                          )
                        }
                        if (
                          activeToolPathology === 'Inverter' &&
                          name === 'Inverter'
                        ) {
                          return handleDisableTool({
                            setTool: setToolsBarPathology,
                            setActiveTool: setActiveToolPathology,
                            array: toolsBarPathology
                          })
                        }
                        return handleActiveTool({
                          name,
                          tool,
                          array: toolsBarPathology,
                          setTool: setToolsBarPathology,
                          setActive: setActiveToolPathology
                        })
                      }}
                      key={name}
                    >
                      {icon({
                        size: 15,
                        color: active ? '#00C1FA' : '#FFFFFF'
                      })}
                      {name}
                    </button>
                  ))}
                </Box>
                <Box
                  flex="1"
                  display="flex"
                  alignItems="center"
                  paddingLeft="10px"
                >
                  <CornerstoneViewport
                    imageIds={selectedPathology.image}
                    tools={TOOLS_CORNERSTONE}
                    onElementEnabled={elementEnabledEvt => {
                      setCornerStoneElementPathology(elementEnabledEvt.detail)
                    }}
                    activeTool={activeToolPathology}
                    viewportOverlayComponent={() => null}
                    style={{ height: '512px', flex: '1' }}
                    isStackPrefetchEnabled
                    eventListeners={[
                      {
                        target: 'cornerstone',
                        eventName: 'cornerstonetoolsstackscroll',
                        handler: evt => {
                          const hasSOP = imagesIDS.findIndex(
                            item =>
                              item.sop_instance_uid ===
                              selectedPathology.sop_instance_uid[
                                evt.detail.newImageIdIndex
                              ]
                          )
                          setActiveImage(prevState =>
                            hasSOP === -1 ? prevState : hasSOP
                          )
                        }
                      }
                    ]}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          xs={5}
          itemspacing={0}
          bgcolor="#F4F7FE"
          display="flex"
          flexDirection="column"
          paddingBottom="20px"
        >
          {validationContent && (
            <Header
              validationContent={validationContent}
              setLoading={setLoading}
              setGetHandle={setGetHandle}
              setWindowingDefaultActive={setWindowingDefaultActive}
              predictions={predictions}
              normalButtonDisabled={normalButtonDisabled}
              total={total}
              handleSubmit={() =>
                handleSubmit({
                  roi_list: roiArray,
                  predictions,
                  modality: project.modality,
                  correctLocalComments: correctLocalComments
                })
              }
              history={history}
              project={project}
              disabledFinish={[
                total.total - 1 !== total.total_validated,
                nextButtonDisabled
              ].includes(true)}
            />
          )}
          <Grid container p="18px">
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography
                variant="subtitle2"
                color="#0310C9"
                fontSize="16px"
                fontWeight="700"
                marginBottom="10px"
              >
                Avaliação
              </Typography>
              <Box paddingTop="10px" paddingBottom="10px">
                <table>
                  <thead>
                    <tr>
                      <th
                        className={classes.th}
                        style={{
                          paddingLeft: 0
                        }}
                      >
                        Patologias<br/> Existentes
                      </th>
                      <th className={classes.th}>Porcentagem Encontrada</th>
                      <th className={classes.th}>Exibir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validationContent &&
                      validationContent?.predictions.map((item, index) =>
                        TRANSLATE_CLASSIFICATION_CLASS[item.model_class] !=
                        'Sem achados' ? (
                          <tr key={index}>
                            <td
                              className={classes.td}
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              {TRANSLATE_CLASSIFICATION_CLASS[item.model_class]}
                            </td>
                            <td className={classes.td}>
                              {Math.round(item.score)}%
                            </td>
                            <td className={classes.td}>
                              <Radio
                                size="small"
                                checked={
                                  selectedPathology.name === item.model_class
                                }
                                onClick={event => {
                                  if (
                                    selectedPathology.name === item.model_class
                                  ) {
                                    setSelectedPathology({
                                      name: '',
                                      image: ''
                                    })
                                  } else {
                                    setSelectedPathology({
                                      name: event.target.value,
                                      image: item.mask_image_path.map(
                                        e => e.url
                                      ),
                                      sop_instance_uid: item.mask_image_path.map(
                                        e => e.sop_instance_uid
                                      )
                                    })
                                  }
                                }}
                                disabled={
                                  !item.score ||
                                  !['high', 'certain', 'probable'].includes(
                                    item.possibility
                                  ) ||
                                  item.model_class === 'nofinding' ||
                                  item.model_class === 'somefinding'
                                }
                                value={item.model_class}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': item.model_class }}
                              />
                            </td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </table>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography
                variant="subtitle2"
                color="#0310C9"
                fontSize="16px"
                fontWeight="700"
                marginBottom="10px"
              >
                Opinião Médica
              </Typography>
              <Box
                border="1px solid #00C1FA"
                marginTop="10px"
                paddingBottom="10px"
              >
                <table>
                  <thead>
                    <tr>
                      <th
                        className={classes.th}
                        style={{
                          color: '#00C1FA',
                          paddingRight:'20px'
                        }}
                      >
                        Patologia<br/> Presente
                      </th>
                      <th
                        className={classes.th}
                        style={{
                          color: '#00C1FA',
                        }}
                      >
                        Local Correto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {predictions.length > 0 &&
                      validationContent &&
                      validationContent?.predictions.map((item, index) =>
                        TRANSLATE_CLASSIFICATION_CLASS[item.model_class] !=
                        'Sem achados' ? (
                          <tr key={index}
                              style={{
                                paddingTop:0,
                              }}
                          >
                            <td className={classes.td}>
                              <Checkbox
                                size="small"
                                name={`correct_location_${item.model_class}`}
                                checked={Boolean(
                                  predictions.find(
                                    prediction =>
                                      prediction.prediction_id === item.id
                                  )?.is_present
                                )}
                                onClick={event => {
                                  const oldPredictions = predictions.filter(
                                    prediction =>
                                      prediction.prediction_id !== item.id
                                  )
                                  const currentPrediction = predictions
                                    .filter(
                                      prediction =>
                                        prediction.prediction_id === item.id
                                    )
                                    .map(prediction => ({
                                      ...prediction,
                                      is_present: event.target.checked
                                    }))
                                  setPredictions([
                                    ...oldPredictions,
                                    ...currentPrediction
                                  ])
                                  if (event.target.checked){
                                    setActivePathologies([...activePathologies, item.model_class]
                                  )
                                  }
                                  else{
                                    const newArray = activePathologies.filter(pathology => pathology !== item.model_class)
                                    setActivePathologies(newArray)
                                  }
                                }}
                              />
                            </td>
                            <td className={classes.td} style={{paddingLeft:'30px'}}>
                              <RadioGroup
                                aria-labelledby={`presence_${item.model_class}`}
                                name={`presence_${item.model_class}`}
                                row
                              >
                                <Box display="flex">
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio size="small" />}
                                    label="Sim"
                                    disabled={
                                      !item.score ||
                                      !['high', 'certain', 'probable'].includes(
                                        item.possibility
                                      ) ||
                                      item.model_class === 'nofinding' ||
                                      item.model_class === 'somefinding' || nextButtonDisabled || !activePathologies.includes(item.model_class)
                                    }
                                    onClick={event => {
                                      predictions.map(prediction => {
                                        if (prediction.prediction_id == item.id){
                                          prediction.is_local_correct = event.target.checked
                                        }
                                      }
                                      )
                                      setModalPresence({
                                        open: false,
                                        position: {
                                          x: 0,
                                          y: 0
                                        },
                                        id: null
                                      })
                                    }}
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio size="small" />}
                                    label="Não"
                                    style={{
                                      ...(correctLocalComments.find(
                                        e => e.id === item.id
                                      )?.comment && {
                                        textDecoration: 'underline',
                                        color: '#1A73E8'
                                      })
                                    }}
                                    onClick={event => {
                                      predictions.map(prediction => {
                                        if (prediction.prediction_id == item.id){
                                          prediction.is_local_correct = false
                                        }
                                      }
                                      )
                                      const isActive =
                                        !item.score ||
                                        ![
                                          'high',
                                          'certain',
                                          'probable'
                                        ].includes(item.possibility) ||
                                        item.model_class === 'nofinding' ||
                                        item.model_class === 'somefinding'

                                      if (!isActive && !nextButtonDisabled && activePathologies.includes(item.model_class)) {
                                        const rect = event?.currentTarget?.getBoundingClientRect()
                                        setModalPresence({
                                          open: true,
                                          position: {
                                            x: rect.left - 269,
                                            y: rect.top - 145
                                          },
                                          id: item.id
                                        })

                                        setCorrectLocalComments(prevState => {
                                          const currentItemExist = prevState.filter(
                                            prevItem => prevItem.id === item.id
                                          )

                                          if (currentItemExist.length > 0) {
                                            return prevState
                                          }

                                          return [...prevState, item]
                                        })
                                      }
                                    }}
                                    disabled={
                                      !item.score ||
                                      !['high', 'certain', 'probable'].includes(
                                        item.possibility
                                      ) ||
                                      item.model_class === 'nofinding' ||
                                      item.model_class === 'somefinding' || nextButtonDisabled || !activePathologies.includes(item.model_class)
                                    }
                                  />
                                </Box>
                              </RadioGroup>
                            </td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </table>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: '100%', mt: '30px' }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <img src={writeIcon} alt="writeIcon" />
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  renderValue={value => {
                    return value?.label || 'Patologias Extras'
                  }}
                  displayEmpty
                  sx={{ height: '40px', minWidth: '300px' }}
                  autoWidth
                  value={markPathology}
                  onChange={event => {
                    if (
                      event.target.value.type === 'BadQuality' ||
                      event.target.value.type === 'NoFinding'
                    ) {
                      setActiveToolDefault('Wwwc')
                      roiArray
                        .filter(item => item.imageIdIndex === activeImage)
                        .filter(item => item.pathology !== 'NoFinding')
                        .filter(item => item.pathology !== 'BadQuality')
                        .forEach(roi => {
                          handleRemoveRoi(roi)
                        })

                      setRoiArray(prevState => {
                        return [
                          ...prevState.filter(
                            item => +item.imageIdIndex !== +activeImage
                          ),
                          {
                            exam_id: imagesIDS[activeImage]?.exam_id,
                            label: event.target.value.label,
                            pathology: event.target.value.value,
                            imageIdIndex: activeImage,
                            color: event.target.value.color,
                            type: 'ROI',
                            label_type: event.target.value.type,
                            serie_instance_uid:
                              validationContent.serie_instance_uid,
                            sop_instance_uid:
                              imagesIDS[activeImage].sop_instance_uid,
                            study_category: validationContent.study_category,
                            study_instance_uid:
                              validationContent.study_instance_uid,
                            modality: validationContent.modality,
                            roi_points: []
                          }
                        ]
                      })
                    } else {
                      handleDisableTool({
                        setTool: setToolsBarDefault,
                        setActiveTool: setActiveToolDefault,
                        array: toolsBarDefault
                      })
                      setActiveToolDefault(event.target.value.type)
                      setRoiArray(prevState => [
                        ...prevState.filter(
                          item => item.imageIdIndex !== activeImage
                        ),
                        ...prevState
                          .filter(item => item.imageIdIndex === activeImage)
                          .filter(item => item.pathology !== 'NoFinding')
                          .filter(item => item.pathology !== 'BadQuality')
                      ])
                    }
                    setMarkPathology(event.target.value)
                  }}
                >
                  <MenuItem value="">
                    <em>Patologias Extras</em>
                  </MenuItem>
                  {validationContent &&
                    SELECT_MARKER.filter(item => !predictionClasses.includes(item.label))
                    .map(item => ({
                      label: item.label,
                      value: item.type,
                      color: item.color,
                      type: item.type
                    })).map(item => {
                      return <MenuItem value={item}>{item.label}</MenuItem>
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: '100%', mt: '30px' }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="10px"
            >
              <button
                className={classes.button}
                onClick={() => setOpenDialogSkip(true)}
                disabled={total.total - 1 === total.total_validated}
              >
                Pular
              </button>
              <Box>
                <strong>
                  {total.total_validated + 1} de {total.total}
                </strong>
              </Box>
              <button
                className={classes.button}
                onClick={() => {
                  setGetHandle('next')
                  setWindowingDefaultActive(null)
                  setLoading(true)
                }}
                disabled={
                  total.total - 1 === total.total_validated
                    ? true
                    : nextButtonDisabled
                }
              >
                Próximo
              </button>
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            sx={{ maxWidth: '100%', mt: '30px' }}
            padding="10px"
          ></Box>

          <Typography
            variant="subtitle2"
            color="#000000"
            fontSize="18px"
            fontWeight="700"
            marginLeft="18px"
          >
            Tags
          </Typography>

          {roiArray.length > 0 && (
            <Box
              py="10px"
              px="18px"
              display="flex"
              gap="10px"
              flexWrap="wrap"
              marginTop="5px"
            >
              {roiArray
                .filter(item => item.imageIdIndex === activeImage)
                .map(roi => (
                  <Box
                    key={roi.id}
                    padding="3px 15px"
                    display="flex"
                    gap="10px"
                    bgcolor={roi.color}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="5px"
                  >
                    {roi.pathology !== 'NoFinding' &&
                      roi.pathology !== 'BadQuality' && (
                        <VisibilityIcon
                          style={{
                            color: '#fff',
                            fontSize: '18px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            roi.data.measurementData.visible = !roi.data
                              .measurementData.visible
                            cornerstone.updateImage(roi.data.element)
                          }}
                        />
                      )}

                    <Typography
                      color="#fff"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleHighlight(roi)
                      }}
                    >
                      {roi.label}
                    </Typography>
                    <ClearIcon
                      style={{
                        color: '#fff',
                        fontSize: '18px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        if (
                          roi.pathology === 'NoFinding' ||
                          roi.pathology === 'BadQuality'
                        ) {
                          setRoiArray(prevState => [
                            ...prevState.filter(
                              item => item.imageIdIndex !== activeImage
                            ),
                            ...prevState
                              .filter(item => item.imageIdIndex === activeImage)
                              .filter(item => item.pathology !== 'NoFinding')
                              .filter(item => item.pathology !== 'BadQuality')
                          ])
                          setMarkPathology('')
                        } else {
                          handleRemoveRoi(roi)
                        }
                      }}
                    />
                  </Box>
                ))}
              {pathologyNothing.length > 0 &&
                pathologyNothing
                  .filter(item => item.activeImage === activeImage)
                  .map(item => (
                    <Box
                      key={item.label}
                      padding="3px 15px"
                      display="flex"
                      gap="10px"
                      bgcolor={item.color}
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="5px"
                    >
                      <Typography color="#fff">{item.label}</Typography>
                      <ClearIcon
                        style={{
                          color: '#fff',
                          fontSize: '18px',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setPathologyNothing(prevState =>
                            prevState.filter(
                              pathology => pathology.label !== item.label
                            )
                          )
                          setRoiArray(prevState =>
                            prevState.filter(
                              roi => roi.pathology !== item.label
                            )
                          )
                          setMarkPathology('')
                        }}
                      />
                    </Box>
                  ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
