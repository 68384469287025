import React from 'react'
import { Route, Switch, useLocation, withRouter } from 'react-router-dom'
import classnames from 'classnames'

import useStyles from './styles'

import Header from '../Header'
import Sidebar from '../Sidebar'



import { useLayoutState } from '../../context/LayoutContext'

function Layout({ history, children }) {
  var classes = useStyles()
  var layoutState = useLayoutState()
  const location = useLocation()
  return (
    <div className={classes.root}>
      <>
        <Header history={history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch location={location}>{children}</Switch>
        </div>
      </>
    </div>
  )
}

export default withRouter(Layout)
