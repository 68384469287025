import React from "react";
import { Spring } from "react-spring";
// styles
import useStyles from "./styles";

export const VerticalProgress = ({ progress }) => {

  var classes = useStyles();

  return (
    <>
      <Spring from={{ percent: 0 }} to={{ percent: progress }}>
      {({ percent }) => (
        <div className={classes.progressVertical}>
          <div style={{ height: `${progress}vh` }} className={classes.progressBar}/>
        </div>
      )}
      </Spring>
    </>
  );
};

export default VerticalProgress;


