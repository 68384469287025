import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  th: {
    padding: '0 10px',
    color: '#021439',
    fontSize: '14px',
  },
  td: {
    textAlign: 'center',
    color: '#021439',
    fontWeight: 500,
    fontSize: '14px',
  },
  button: {
    boxShadow: '0px 4px 4px 0px #00000040',
    backgroundColor: '#0310C9',
    fontSize: '14px',
    border: '1px solid #6877FF',
    width: '115px',
    color: '#fff',
    fontWeight: '500',
    height: '35px',
    borderRadius: '6px',
    padding: '10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    "&:disabled": {
      backgroundColor: '#AFAFAF',
      color: '#fff',
      border: 'none',
    }
  },
  highlighted: {
    backgroundColor: 'yellow'
  }
})
