
export const TRANSLATE_CLASSIFICATION_CLASS = {
  effusion: 'Derrame pleural',
  mass: 'Massa',
  cardiomegaly: 'Cardiomegalia',
  bacterial_pneumonia: 'Pneumonia bacteriana',
  opacity: 'Opacidade',
  pneumothorax: 'Pneumotórax',
  edema: 'Edema',
  pneumonia: 'Pneumonia',
  ateromatose: 'Ateromatose',
  covid: 'Covid',
  covid19: 'Covid',
  nofinding: 'Sem achados',
  finding: 'Possível achado',
  normal: 'Sem achados',
  head_critical: 'Critico',
  brainbleed: 'Hemorragia cerebral',
  hernia: 'Hernia',
  fratura: 'Fratura',
  critico: 'Critico',
  somefinding: 'Achado detectado',
  cronico: 'Cronico',
  ARCH_DISTORT: "ARCH_DISTORT",
  BIRADS0: "BIRADS0",
  BIRADS1: "BIRADS1",
  BIRADS2: "BIRADS2",
  BIRADS3: "BIRADS3",
  BIRADS4: "BIRADS4",
  CALCIFICATION: "CALCIFICATION",
  INFLAMATORY: "INFLAMATORY",
  LYMPHNODE: "LYMPHNODE",
  MASS_NODULE: "MASS_NODULE",
  OTHERS: "OTHERS",
  POST_OP: "POST_OP",
  ABDOMEN_ABN: "ABDOMEN_ABN",
  CERVICAL_ABN: "CERVICAL_ABN",
  ENDOMETRIOSIS: "ENDOMETRIOSIS",
  ENDOMETRIUM_ABN: "ENDOMETRIUM_ABN",
  FLUID_COLLECTION: "FLUID_COLLECTION",
  MALF_VARI_CONG: "MALF/VARI/CONG",
  MYOMA: "MYOMA",
  OBSTETRIC: "OBSTETRIC",
  OVARY_ABN: "OVARY_ABN",
  POSTOP_DEVICE: "POSTOP_DEVICE",
  TUBES_ABN: "TUBES_ABN",
  UTERUS_ABN: "UTERUS_ABN",
  VAGINAL_ABN: "VAGINAL_ABN",
  AORTIC_DISEASE: "AORTIC_DISEASE",
  ARTERIOPATHY: "ARTERIOPATHY",
  CARDIOMEGALY: "CARDIOMEGALY",
  LYMPHNODE: "LYMPHNODE",
  NODULE: "NODULE-MASS",
  OTHERS: "OTHERS",
  PERICARDIAL_EFFUSION: "PERICARDIAL_EFFUSION",
  PLEURAL_EFFUSION: "PLEURAL_EFFUSION",
  PNEUMOPATHY: "PNEUMOPATHY",
  PNEUMOTHORAX: "PNEUMOTHORAX",
  POST_OP: "POST_OP",
  TEP_DISEASE: "TEP_DISEASE",
  AERO_FIST: "AERO_FIST",
  APENDICITIS: "APENDICITIS",
  ASCITES: "ASCITES",
  BILIARY_DILATION: "BILIARY_DILATION",
  CHOLECYSTITIS: "CHOLECYSTITIS",
  CHOLECYSTOPATHY: "CHOLECYSTOPATHY",
  NODULE_MASS: "NODULE_MASS",
  DISTENSION_OBSTRUCTION: "DISTENSION_OBSTRUCTION",
  DIVERTICULITIS: "DIVERTICULITIS",
  DIVERTICULOSIS: "DIVERTICULOSIS",
  ESOPHAGUS: "ESOPHAGUS",
  FLUID_COLLECTION: "FLUID_COLLECTION",
  HEPATOPATHY: "HEPATOPATHY",
  HERNIA: "HERNIA",
  LIVER_BENIGN: "LIVER_BENIGN",
  METASTASIS: "METASTASIS",
  NEOPLASIA: "NEOPLASIA",
  LYMPHNODES: "LYMPHNODES",
  MESENTERIC_ISCHEMIA: "MESENTERIC_ISCHEMIA",
  CYST: "CYST",
  PANCREATITIS_ACUTE: "PANCREATITIS_ACUTE",
  PANCREATITIS_CHRONIC: "PANCREATITIS_CHRONIC",
  PERFORATION: "PERFORATION",
  PNEUMOPERITONEUM: "PNEUMOPERITONEUM",
  SPINE_BONE: "SPINE-BONE",
  SPLENIC_DISORDER: "SPLENIC_DISORDER",
  STEATOSIS: "STEATOSIS",
  TRAUMA: "TRAUMA",
  VASCULAR: "VASCULAR",
  VISCERAL_THICKENING: "VISCERAL_THICKENING",
  ATROPHY: "ATROPHY",
  FACE: "FACE",
  ISCHEMIA: "ISCHEMIA",
  ISCHEMIA_ACUTE: "ISCHEMIA_ACUTE",
  MVC: "MVC",
  SEQUELAE: "SEQUELAE",
  TUMOR: "TUMOR",
  WM: "WM",
  NEPHROPATHY: "NEPHROPATHY",
  RENAL_CYST: "RENAL_CYST",
  TESTICLE: "TESTICLE",
  BLADDER: "BLADDER",
  NEPHROLITIASIS: "NEPHROLITIASIS",
  NOD_MASS: "NOD_MASS",
  MALFORM: "MALFORM",
  URINARY_DILATION: "URINARY_DILATION",
  PROSTATE: "PROSTATE",
  INFLAMMATORY: "INFLAMMATORY",
  SELLA: "SELLA",
  ORBIT: "ORBIT",
  TOXIC_METAB: "TOXIC_METAB",
  HYDROCEPHALUS: "HYDROCEPHALUS",
  HYPERTENSION: "HYPERTENSION",
  HEMORRHAGE: "HEMORRHAGE",
  SKULL: "SKULL",
  EAR: "EAR",
  FLUID_COLLECTION: "FLUID_COLLECTION",
  CALCIFICATION: "CALCIFICATION",
  SPINE_BONE: "SPINE_BONE",
  Cholesterol: "Colesterol",
  BloodGlucose: "Glicose no sangue",
  IMC: "IMC",
  Biomarker: "Biomarcador",
  Hemoglobin: "Hemoglobina",
  Hematocrit: "Hematócrito",
  Platelets: "Plaquetas",
  PCR: "PCR",
  Leukocytes: "Leucócitos",
  PyruvicTransaminase: "Transaminase pirúvica",
  GammaGlutamylTransferase: "Gama Glutamil Transferase",
  AlkalinePhosphatase: "Fosfatase Alcalina",
  CapillaryBloodGlucose: "Glicemia Capilar",
  ClearanceCreatinine: "Liberação de creatinina",
  Triglycerides: "Triglicerídeos",
  HeartRate: "Frequência cardíaca",
  Saturation: "Saturação",
  LDL: "LDL",
  Albumin: "Albumina",
  Urea: "Uréia",
  Phosphor: "Fósforo",
  BloodGas: "Gasometria",
  GlomerularFiltrationRate: "Taxa de filtração glomerular",
  HDL: "HDL",
  TSH: "TSH",
  RespiratoryFrequency: "Frequência Respiratória",
  Insulin: "Insulina",
  PTH: "PTH",
  GlycatedHemoglobin: "Hemoglobina glicada",
  T4: "T4",
  Glasgow: "Glasgow",
  Creatinine: "Creatinina",
  BloodPressure: "Pressão arterial",
  FractionEjection: "Fração de ejeção",
  Troponin: "Troponina",
  Albuminuria: "Albuminúria",
  Ferritin: "Ferritina",
  Diuresis: "Diurese"
  }

export const STATUS = {
  skip: 'SKIP',
  validated: 'VALIDATED'
}
