import axios from "axios";

const Api = axios.create({
    baseURL: window._env_.API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    timeout: 9000,
});

export default Api;