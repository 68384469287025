import React, { useState, useEffect } from 'react'
import { Grid, Box, Card, Button } from '@material-ui/core'

// styles
import useStyles from './styles'

// components
import LoadingOverlay from 'react-loading-overlay'
import Loader from 'react-loader-spinner'
import PackageCard from '../../components/PackageCard'
import { Typography } from '../../components/Wrappers'
import StatusHeader from './components/StatusHeader/StatusHeader'
import classnames from 'classnames'
import ProgressHeader from './components/ProgressHeader'
import ComingSoonOverlay from '../../components/ComingSoonOverlay/ComingSoonOverlay'
import { studyService } from '../../services/studies'

export default function Dashboard(props) {
  var classes = useStyles()
  const [projects, setProjects] = useState([])
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const user = await studyService.fetchUserInfo()
      if (!user.gdpr_accepted) {
        props.history.push('/app/gdpr')
      }
      const response = await studyService.fetchUserPathologies({
        campaign_type: filter
      })
      setProjects(response)
      setLoading(false)
    })()
  }, [filter, props.history])

  return (
    <>
      <ProgressHeader monthlyProgress="12" monthlyGoal="30" />
      <div className={classes.dashboardContainer}>
        <Grid container className={classes.dashboardContainer}>
          <Grid item xs={2}>
            <Box
              display="flex"
              flexDirection="column"
              style={{ height: '100%', padding: '10px 15px 10px 15px' }}
            >
              <Box p={1}>
                <Card square={true} className={classes.welcomeBanner}>
                  <Typography style={{ fontWeight: '700' }}>
                    BEM-VINDO A
                  </Typography>
                  <Typography style={{ fontSize: '2vw', fontWeight: '700' }}>
                    FLORENCE
                  </Typography>
                  <Typography style={{ fontWeight: '700' }}>
                    Primeira ferramenta de Machine Teaching Médico
                  </Typography>
                </Card>
              </Box>
              <Box p={1}>
                <Card style={{}} className={classes.comingSoonBanner}>
                  <Typography
                    style={{
                      fontSize: '36px',
                      fontWeight: '700',
                      color: '#fff'
                    }}
                  >
                    XP POWER
                  </Typography>
                  <Typography style={{ fontWeight: '700', color: '#fff' }}>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '17px',
                      fontWeight: '700',
                      color: '#FFDB41'
                    }}
                  >
                    INICIAR AGORA
                  </Typography>
                  <ComingSoonOverlay />
                </Card>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8} className={classes.dashboardCenterContainer}>
            <Box component="span">
              <StatusHeader />
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                style={{ gap: 10, margin: '10px 0', padding: '10px' }}
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    setFilter(state => (state === 'exam' ? '' : 'exam'))
                  }
                  style={{
                    backgroundColor: filter === 'exam' ? '#0310C9' : '#1A73E8',
                    border: '1px solid #6877FF',
                    width: 'auto',
                    color: '#fff',
                    fontWeight: '500',
                    height: '32px',
                    fontSize: '12px',
                    borderRadius: '6px'
                  }}
                >
                  Avaliação de Exame
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    setFilter(state => (state === 'model' ? '' : 'model'))
                  }
                  style={{
                    backgroundColor: filter === 'model' ? '#0310C9' : '#1A73E8',
                    border: '1px solid #6877FF',
                    width: 'auto',
                    color: '#fff',
                    fontWeight: '500',
                    height: '32px',
                    fontSize: '12px',
                    borderRadius: '6px'
                  }}
                >
                  Validação de Modelo
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    setFilter(state => (state === 'quality' ? '' : 'quality'))
                  }
                  style={{
                    backgroundColor: filter === 'quality' ? '#0310C9' : '#1A73E8',
                    border: '1px solid #6877FF',
                    width: 'auto',
                    color: '#fff',
                    fontWeight: '500',
                    height: '32px',
                    fontSize: '12px',
                    borderRadius: '6px'
                  }}
                >
                  Validação de quality
                </Button>
              </Box>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: base => ({ ...base, background: 'transparent' })
                }}
                spinner={
                  <Loader
                    type="Bars"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  />
                }
              >
                <Grid container spacing={4} style={{ padding: '10px' }}>
                  {projects.length > 0 &&
                    projects.map(project => (
                      <Grid item xs={4}>
                        <PackageCard
                          {...project}
                          upperTitle
                          bodyClass={classes.fullHeightBody}
                          className={classes.card}
                        />
                      </Grid>
                    ))}
                </Grid>
              </LoadingOverlay>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.bannerContainer}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classnames(
                classes.dashBanner,
                classes.rightSideBanner
              )}
            >
              <Typography variant="h3">
                <Box fontWeight="fontWeightBold">Leaderboards</Box>
              </Typography>
              <ComingSoonOverlay />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
