import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    maxWidth: 345,
    borderRadius: '2px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  packageHeader: {
    padding: '10px',
    // textAlign: 'center',
  },
  packageHeaderText: {
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  packageSubHeaderText: {
    fontWeight: 700,
    fontSize: '0.7rem',
  },
  packageHeaderAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: '#23445A',
  },
  packageContent: {
    paddingBottom: '0px',
  },
  packageContentText: {
    fontSize: '0.7rem',
  },
  packageActionButton: {
    backgroundColor: '#1A73E8',
    border: '1px solid #ffff',
    width: '140px',
    color: '#fff',
    fontWeight: '500',
    height: '30px',
    fontSize: '12px',
    borderRadius: '6px',
    padding: '10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  packageActionPriceLabel: {
    marginLeft: '3px',
    fontWeight: 900,
    color:'#6A0FEE',
    fontSize: '18px',
  },
}));
