export const sampleData = {
    // documentDetails:{
        "body_part": "chest",
        "type": "SENTENCE",
        "source_id": "",
        "pathology_info_id": "",
        "doc_id": "",
        "text": "",
        "created": "2021-08-23T19:10:29.461923Z",
        "updated": "2021-08-23T19:10:29.461923Z",
        "id": "",
        "pathology": {"pathology_list": [{"label":""}]},
        "document": {
            "sex": "m",
            "source_id": "",
            "pathology_info_id": "",
            "text": "Mr Tan Ah Kow was accompanied by his son, Mr Tan Ah Beng, for the examination.\nMr Tan is a 55 year old man, who is divorced, and unemployed. Mr Tan is currently\nliving with his son, Ah Beng, in Ah Beng’s flat. Mr Tan Ah Beng informed me that\nMr Tan Ah Kow used to work as a cleaner.\nMr Tan Ah Kow has a history of medical conditions. He has had hypertension and\nhyperlipidemia since 1990 and suffered several strokes in 2005. He subsequently\ndeveloped heart problems (cardiomyopathy), cardiac failure and chronic renal disease\nand was treated in ABC Hospital.\nHe was last admitted to the ABC Hospital on 1 April 2010 till 15 April 2010, during\nwhich he was diagnosed to have suffered from a stroke. This was confirmed by CT\nand MRI brain scans. "
        }
    // },
    // pharseArray: [
    //     {
    //         'text': 'Severe chronic miscrovascular degenerative change. Periventtricular deep white matter most like related to small vessel ischemic disease',
    //     },
    //     {
    //         'text': 'Has hypertension and hyperlipidemia since 1990 and suffered several strokes in 2005',
    //     },
        
    //     '',
    // ],
    // pathologiesArray:{
    //     0:[],
    //     1:['Heart #1', 'Heart #2', 'Heart #3', 'Heart #4'],
    //     2:['Brain #1', 'Brain #2', 'Brain #3', 'Brain #4', 'Brain #5', 'Brain #6', 'Brain #7', 'Brain #8'],
    //     3:['Skull #1', 'Skull #2', 'Skull #3', 'Skull #4'],
    //     4:['Lung #1', 'Lung #2', 'Lung #3'],
    //     5:['Breast #1'],
    //     6:['Face #1', 'Face #2', 'Face #3'],
    // }
}
