import axios from "axios";

export const AuthApi = axios.create({
  baseURL: window._env_.API_URL,
  headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	}
});

AuthApi.interceptors.request.use(request => {
  request.headers.Authorization =  `Bearer ${localStorage.getItem("token")}`;
  return request;
});

AuthApi.interceptors.response.use((response) => response, (error) => {
  if(error.response?.status === 403){
    localStorage.removeItem('token');
    window.location = '/login';
  }
  return error;
});

export default AuthApi;