import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  appBar:{
    maxHeight:"72px",
    boxShadow: "0px -2px 15px -7px rgba(0,0,0,0.30)",
  },
  progressToolBar:{
    justifyContent:"center",
    backgroundColor:"#fff",
    color:"#214459",
    fontSize:"14px",
    fontWeight:"500"

  },
  progressItem:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    margin: "0px 20px 0px 20px"
  },
  progressItemTitle:{
    fontSize:"1rem",
  },
  valuedChip:{
    display:"flex",
    flexDirection:"row",
    
    alignItems:"center",
    backgroundColor:"#214459",
    borderRadius:"100px",
    height:"30px",
    color:"white",
    padding:"2px 30px 2px 30px",
    margin: "0px 20px 0px 20px"
  },
  chipValueText:{
    fontSize:"20px",
    margin:"0px",
    fontWeight:"600"
  },
  progressBar:{
    width:"425px",
    margin: "0px 27px 0px 27px",
  },
  
}));
