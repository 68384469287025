import React from 'react';

import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import {
  Paper,
  Grid,
  Button,
  CssBaseline,
  MenuItem,
  FormControl,
} from '@material-ui/core';

import { studyService } from '../../services/studies';

const onSubmit = async values => {
  values.path_thumbnail = "/static/images/cr_2.png"
  values.path_icon = "/static/images/ribs.svg"
  values.package_value = 5
  values.exams_in_package = 10
  values.pathology_list = JSON.parse(values.pathology_list)
  studyService.putCampaign(values)
};

const onCreate = async values => {
  values.path_thumbnail = "/static/images/cr_2.png"
  values.path_icon = "/static/images/ribs.svg"
  values.package_value = 5
  values.exams_in_package = 10

  values.pathology_list = JSON.parse(values.pathology_list)
  studyService.postNewCampaign(values, values.bucket)
};

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

export const FormCampaign = props => {
  const { handleSubmit, pristine, submitting } = props;
  return (
      <div style={{ padding: 16, margin: 'auto', maxWidth: 600 }}>
        <CssBaseline />
        <Form
          onSubmit={props.type == 'modify' ? onSubmit : onCreate}
          render={({ handleSubmit, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="name"
                      required
                      component={TextField}
                      type="text"
                      label="Nome da campanha"
                      
                      defaultValue={props.type == 'modify' ? props.rowData.campaignName : null}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="description"
                      component={TextField}
                      multiline
                      label="Descrição"
                      initialValue={props.type == 'modify' ? props.rowData.description : null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="pathology_list"
                      fullWidth
                      required
                      component={TextField}
                      multiline
                      label="Lista de patologias"
                      initialValue={props.type == 'modify' ? JSON.stringify(props.rowData.originalPathologyList) : null}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="bucket"
                      component={TextField}
                      type="text"
                      label="Nome do Bucket"
                      initialValue={props.type == 'modify' ? props.rowData.bucketName : null}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="sub_title"
                      component={TextField}
                      type="text"
                      label="Subtítulo"
                      initialValue={props.type == 'modify' ? props.rowData.subTitle : null}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="modality"
                      component={Select}
                      label="Modalidade"
                      formControlProps={{ fullWidth: true }}
                      initialValue={props.type == 'modify' ? props.rowData.modality : null}
                    >
                      <MenuItem value="cr">CR</MenuItem>
                      <MenuItem value="ct">CT</MenuItem>
                      <MenuItem value="ecg">ECG</MenuItem>
                      <MenuItem value="nlp">NLP Prontuario</MenuItem>
                      <MenuItem value="nlp">NLP Laudo</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field 
                      fullWidth
                      required
                      name="body_part"
                      component={Select}
                      label="Body Part"
                      formControlProps={{ fullWidth: true }}
                      initialValue={props.type == 'modify' ? props.rowData.bodyPart : null}
                    >
                      <MenuItem value="MAMMO">Breast</MenuItem>
                      <MenuItem value="BONES">Ossos</MenuItem>
                      <MenuItem value="GENERAL">Geral (NLP)</MenuItem>
                      <MenuItem value="SKULL">Crânio</MenuItem>
                      <MenuItem value="CHEST">Tórax</MenuItem>                      
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      disabled = {props.type == 'modify' ? true : false}
                      fullWidth
                      required
                      name="campaign_type"
                      component={Select}
                      label="Tipo de Campanha"
                      formControlProps={{ fullWidth: true }}
                      initialValue={props.type == 'modify' ? props.rowData.campaignType : null}
                    >
                      <MenuItem value="model">Modelo</MenuItem>
                      <MenuItem value="exam">Exame</MenuItem>
                      <MenuItem value="quality">Qualidade</MenuItem>
                    </Field>
                  </Grid> 
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="status"
                      component={Select}
                      label="Status"
                      formControlProps={{ fullWidth: true }}
                      initialValue={props.type == 'modify' ? props.rowData.status : null}
                    >
                      <MenuItem value="active">Ativo</MenuItem>
                      <MenuItem value="paused">Pausado</MenuItem>
                    </Field>
                  </Grid>   
                  <Condition when="campaign_type" is="model">
                  <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="user_id"
                        component={Select}
                        type="text"
                        label="Médico(a) responsável"
                        formControlProps={{ fullWidth: true }}
                        initialValue={props.type == 'modify' ? props.rowData.doctor : null}
                      >
                      <MenuItem value="a451d158-da77-4db3-8158-c0526807a0cb">Dr. André Castilla</MenuItem>
                      </Field>
                  </Grid>
                  </Condition>              
                  <Grid item style={{ marginTop: 16, marginLeft: "auto", marginRight: 0 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                    >
                    {props.type == 'modify' ? "Modificar" : 'Criar'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
  );
}
export default FormCampaign;