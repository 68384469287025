import React from 'react'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import { Api as api } from '../api'
import { studyService } from '../services/studies'

var UserStateContext = React.createContext()
var UserDispatchContext = React.createContext()

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true }
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider({ children }) {
  // let user = userStore(state => state.user)

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('token')
  })

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function useUserState() {
  var context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}

// ###########################################################
export const userStore = create(persist(set => ({
  user: {},
  isAuthenticated: false,
  userBilling: {},
  packagePrice: 0,
  setAuthentication: () => {
    set(state => ({ isAuthenticated: !state.isAuthenticated }))
  },
  setUser: () => {
    studyService.fetchUserInfo().then(response => {
      set({ user: response })
    })
  },
  setUserBilling: () => {
    studyService.fetchUserBilling().then(response => {
      set({ userBilling: response.data })
    })
  },
  setPackagePrice: newPrice => {
    set({ packagePrice: newPrice });
  },
  removeUserData: () => {
    set({ user: {} });
    set({ userBilling: {} })
  }
})))

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut }

function loginUser(login, password, history, setIsLoading, setError, setUser, setAuthentication, setUserBilling, props) {
  setError(false)
  setIsLoading(true)
  let params = new URLSearchParams()
  params.append('username', login)
  params.append('password', password)

  api
    .post(`/api/v1/login/access-token`, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(result => {
      localStorage.setItem('token', result.data.access_token)
      setUser()
      setAuthentication()
      setUserBilling()
      props.history.push('/app/dashboard')
    })
    .catch(err => {
      console.log(err)
      setError(true)
      setIsLoading(false)
    })
}

function signOut(history) {
  localStorage.removeItem('token')
  history.push('/login')
}
