import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom'
import {Grid, Card, Chip, CardContent, CardActions, Typography, 
  Paper, Divider, Box, FormControl,
  FormControlLabel} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import ViewportSidebarConfirmation from "../ViewportSidebarConfirmation/ViewportSidebarConfirmation";
import {NumericalBadge} from '../Wrappers';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { v4 as uuidv4 } from 'uuid';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "react-loader-spinner";
import Hotkeys from 'react-hot-keys';

import { studyService } from '../../services/studies';
import { userStore } from "../../context/UserContext";
import { sampleData } from './data';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
// styles
import useStyles from "./styles";

export const Nlp = ({ className, ...rest }) => {
  
  const classes = useStyles();

  let { id, category } = useParams();
  let history = useHistory()

  const userBilling = userStore.getState().userBilling

  const [resultsArray, setResultsArray] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [studyId, setStudyId] = useState('');
  const [nextStudyId, setNextStudyId] = useState('');
  const [nextStudyDetails, setNextStudyDetails] = useState('');
  
  const [studyDetails, setStudyDetails] = useState(sampleData);
  const [activePart, setActivePart] = useState('');
  const [keyList, setKeyList] = useState([]);
  const [pathologyDict, setPathologyDict] = useState({});
  const user = userStore.getState().user

  useEffect(() => {
    if(isSubmit){
      setIsLoaded(false)
      if(nextStudyId)
        setStudyId(nextStudyId)
      setNextStudyId('')
      setResultsArray([])
      setKeyList([])
    }
    if(!isSubmit && (nextStudyId !== studyId)){
      setStudyId(nextStudyId)
      setNextStudyDetails([])
    }

  }, [isSubmit]);
  
  useEffect(() => {
    if(studyId === ''){
      studyService.fetchNextExamId(category , id)
        .then(response => {
          if(response === undefined) {
            history.push('/app/dashboard')
          }
          setStudyId(response[0].data.id)
          setStudyDetails(response[0])
          setActivePart(response[0].data.body_part)
          if(response.length>1){
            setNextStudyId(response[1].data.id)
            setNextStudyDetails(response[1])
          }else{
            setNextStudyId('')
            setNextStudyDetails([''])
          }
          setIsLoaded(true)
        }).catch(err => {
          history.push('/app/dashboard')
        })
    }
  }, [studyId])

  const addClassification = (pathology) => {
    if(resultsArray.some(result => result.classification === pathology))
      return
    setResultsArray(resultsArray => [...resultsArray, 
      {id: uuidv4(), classification:pathology, doc_details_id: studyId, 
        pathology_info_id:id, body_part:activePart}])
  };

  useEffect(() => {
    if(isLoaded){
      const pathologiesDict = {}
      studyDetails.data.pathology.pathology_list.map((key, value) => (
            pathologiesDict[key.label] = key.values[0]
        ))
        setPathologyDict(pathologiesDict)
    }
  }, [isLoaded]);

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x).toLowerCase());
  const numb = [...Array(10).keys()]
  const hotKeysNlpList = alphabet.concat(numb).toString()

  function activateCheckbox(id) {
      var checkbox = document.getElementById(id);
      if (checkbox){
        setKeyList(state => {
          const keyArray = state.indexOf(id)
          if (keyArray > -1) {
            setResultsArray(resultsArray.filter(item => item.classification !== checkbox.name))
            return state.filter(item => item !== id)
          }
          return [...state, id]
        })
        addClassification(checkbox.name)
      }
  }

  return (
    <Hotkeys keyName={hotKeysNlpList} onKeyDown={(event) => activateCheckbox(event)}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Paper square style={{ height: '100%' }}>
            <Grid container spacing={0} direction="row" justify="space-between" style={{ height: '100%' }}>
              <Grid item xs={10} style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                <Paper elevation={0}>
                  <Grid style={{ backgroundColor: 'white', width: '100%' }} direction="column">
                    <Grid item xs={12} style={{ backgroundColor: 'white' }}>
                      <LoadingOverlay active={!isLoaded} 
                            styles={{ overlay: (base) => ({ ...base, background: 'white'})}}
                            spinner={<Loader type="Bars" color="#00BFFF" height={100} width={100}/>}>
                        <Box p={1} style={{paddingTop: '0px'}}>
                          <Card square={true} elevation={3} variant='elevation'>
                            <CardContent>
                              <PopupState variant="popover" popupId="demo-popup-popover">
                                {(popupState) => (
                                  <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <Typography color="#214459" style ={{color:"#214459", fontWeight: 600}} gutterBottom>
                                      Por favor, avalie o texto abaixo e selecione os tópicos correspondentes.
                                    </Typography>
                                    <AssignmentOutlinedIcon {...bindTrigger(popupState)} style={{background:'white', color:'#214459', cursor:'pointer'}} />
                                    <Popover
                                      {...bindPopover(popupState)}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      PaperProps={{
                                        style: { width: '50%' },
                                      }}
                                    >
                                      <Box p={2}>
                                        {isLoaded &&
                                          studyDetails.phrase_list.map((phrase) => {
                                            if(phrase === studyDetails.data.text){
                                              return <p style={{backgroundColor: 'gold'}}>{phrase}</p>
                                            }
                                            return <p>{phrase}</p>
                                          })
                                        }
                                      </Box>
                                    </Popover>
                                  </div>
                                )}
                              </PopupState>
                              <Divider />
                              <div style={{padding:"1%"}}></div>
                              {isLoaded && 
                              <Typography variant="h5" className={classes.stepCardText} >{studyDetails.data.text}</Typography>
                              }
                            </CardContent>
                            <CardActions>
                              <div className={classes.chipContainer} style={{display : 'block'}}>
                                {resultsArray.map((result, key) => (
                                  <Chip key={key} className={classes.chip} label={result.classification} variant="outlined"
                                  deleteIcon={<CloseIcon/>} size="small"
                                  onDelete={() => {setResultsArray(resultsArray.filter(item => item.id !== result.id)); activateCheckbox(pathologyDict[result.classification])}}/>
                                ))}
                              </div>
                            </CardActions>
                          </Card>
                        </Box>
                        <Box p={1} style={{paddingTop: '0px'}}>
                          <Card square={true} elevation={3} variant='elevation'>
                            <CardContent style={{ paddingBottom: '10px' }}>
                              <Typography style={{ color: "#214459" }}>
                                Por favor, selecione os tópicos correspondentes ao texto:
                              </Typography>
                              <div style={{padding:"1%"}}></div>
                              <FormControl>
                                <Grid container spacing={2}>
                                  {Object.entries(pathologyDict).map(([key, value]) => (
                                  <Grid item xs={4}>
                                    <FormControlLabel control={
                                      <Checkbox 
                                        name={key} 
                                        id={value} 
                                        checked={Boolean(keyList.find(item => item === value))}
                                        onClick={() => activateCheckbox(value)}
                                        color="success"
                                      />
                                    }
                                      label={<Typography fontFamily="Roboto" style={{ color: "#214459", fontSize: "14px", fontWeight: 500 }}>{key + " [" + value + "]"}</Typography>}/>
                                  </Grid>
                                  ))}
                                </Grid>
                              </FormControl>
                            </CardContent>
                          </Card>
                        </Box>
                      </LoadingOverlay>    
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2} style={{ backgroundColor: '#23445A'}}>
                <Grid item xs={12} className={classes.confirmationContainer}>
                  <ViewportSidebarConfirmation type={'nlp'} nextStudyDetails={nextStudyDetails}
                    results={resultsArray} studyId={studyId} setIsSubmit={setIsSubmit} 
                    isSubmit={isSubmit}/>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Hotkeys>
  );
};

export default Nlp;
