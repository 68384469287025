import React from "react";
// components
import { Validate } from "../../components/Validate/Validate";

export default function ValidatePage() {

  return (
    <Validate style={{ minWidth: '100%', height: '512px', flex: '1' }}/>
  );
}
