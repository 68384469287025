import { AuthApi } from '../../api'
import { v4 as uuidv4 } from 'uuid'
import { STATUS } from './contants.jsx'

export const handleDisableTool = ({ setTool, setActiveTool, array }) => {
  const allInative = array.map(item => ({
    ...item,
    active: false
  }))
  setTool(allInative)
  setActiveTool('')
}

export const getExam = async ({ id, sop, modality }) => {
  try {
    const response = await AuthApi.get(
      `/api/v1/${modality}/model-validation-detail/${id}/${sop}`
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getNextExam = async ({ id, modality }) => {
  try {
    const response = await AuthApi.get(`/api/v1/${modality}/next/${id}`)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getTotalExams = async ({ id, type }) => {
  try {
    const response = await AuthApi.get(
      `api/v1/users/pathology/count?campaign_id=${id}&exam_type=${type}&exclude_skip=False`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const handleChangeData = async ({
  setImagesIDS,
  data,
  setValidationContent,
  setPredictions,
  setRoiArray,
  setLoading
}) => {
  try {
    setImagesIDS(
      data.imageSet.map(item => ({
        url: item.url.replace('https', 'wadouri'),
        sop_instance_uid: item.sop_instance_uid,
        exam_id: item.exam_id
      }))
    )
    setValidationContent(data)
    setPredictions([])
    setPredictions(
      data.predictions.map(item => ({
        prediction_id: item.id,
        is_present: false,
        prediction_class: item.model_class,
        model_version: item?.model_version || 'v1',
        is_local_correct: false,
        score: item.score
      }))
    )
    setRoiArray(
      data.imageSet.map(item => ({
        id: uuidv4(),
        exam_id: data.id,
        label: '',
        pathology: '',
        roi_points: [],
        serie_instance_uid: data.serie_instance_uid,
        sop_instance_uid: item.sop_instance_uid,
        study_category: data.study_category,
        study_instance_uid: data.study_instance_uid,
        modality: data.modality
      }))
    )
    setLoading(false)
  } catch (err) {
    console.log('err', err)
  }
}

export const handleSubmit = async ({
  roi_list,
  predictions,
  modality,
  correctLocalComments
}) => {
  try {
    const roi = roi_list
      .map(item => ({ ...item, data: '' }))
      .filter(item => item.pathology !== '')
      .map(item => ({ ...item, pathology: item.label }))

    const response = await AuthApi.post(`/api/v1/${modality}/model-feedback`, {
      roi_list:
        roi.length > 0
          ? roi
          : [
              roi_list.map(item => ({
                ...item,
                pathology: STATUS.validated
              }))[0]
            ],
      predictions: predictions.map(item => {
        const comments =
          correctLocalComments.find(i => i.id === item.prediction_id)
            ?.comment || null
        return {
          ...item,
          validation_text: comments
        }
      })
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const handleNextExam = async ({
  setLoading,
  roiArray,
  predictions,
  project,
  setTotal,
  setMarkPathology,
  nextData,
  setImagesIDS,
  setValidationContent,
  setPredictions,
  setRoiArray,
  setNextData,
  setSelectedPathology,
  setNextLoading,
  correctLocalComments,
  setCorrectLocalComments
}) => {
  try {
    setImagesIDS([])
    setLoading(true)
    await handleSubmit({
      roi_list: roiArray,
      predictions,
      modality: project.modality,
      correctLocalComments
    })
    const total = await getTotalExams({
      id: project.campaign_id,
      type: project.modality
    })
    setTotal(total)
    setMarkPathology('')
    setSelectedPathology({
      name: '',
      image: []
    })
    handleChangeData({
      data: nextData.data,
      setImagesIDS,
      setValidationContent,
      setPredictions,
      setRoiArray,
      setLoading
    })
    setNextLoading(true)
    const nextResponse = await getNextExam({
      id: project.campaign_id,
      modality: project.modality
    })
    const responseNext = await getExam({
      id: project.campaign_id,
      sop: nextResponse.data[1],
      modality: project.modality
    })
    setNextData(responseNext)
    setCorrectLocalComments([])
    setNextLoading(false)
  } catch (error) {
    console.log(error)
  }
}

export const handleFinishCampaing = async ({ id }) => {
  try {
    const response = await AuthApi.put(
      `api/v1/users/status?campaign_id=${id}&status=finished`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const handleSkipcampaing = async ({
  setOpenDialogSkip,
  setLoading,
  roiArray,
  predictions,
  project,
  setTotal,
  setMarkPathology,
  nextData,
  setImagesIDS,
  setValidationContent,
  setPredictions,
  setRoiArray,
  setNextData,
  setSelectedPathology,
  setNextLoading,
  setCorrectLocalComments,
}) => {
  try {
    setImagesIDS([])
    setCorrectLocalComments([])
    setOpenDialogSkip(false)
    setLoading(true)
    await AuthApi.post(`/api/v1/${project.modality}/model-feedback`, {
      roi_list: [
        roiArray.map(item => ({ ...item, pathology: STATUS.skip }))[0]
      ],
      predictions
    })

    const total = await getTotalExams({
      id: project.campaign_id,
      type: project.modality
    })
    setTotal(total)
    setMarkPathology('')
    setSelectedPathology({
      name: '',
      image: []
    })
    handleChangeData({
      data: nextData.data,
      setImagesIDS,
      setValidationContent,
      setPredictions,
      setRoiArray,
      setLoading
    })
    setNextLoading(true)
    const nextResponse = await getNextExam({
      id: project.campaign_id,
      modality: project.modality
    })

    const responseNext = await getExam({
      id: project.campaign_id,
      sop: nextResponse.data[1],
      modality: project.modality
    })
    setNextData(responseNext)
    setNextLoading(false)
  } catch (error) {
    console.log(error)
  }
}
