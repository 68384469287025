import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  overlay:{
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display:'flex',
    flex:1,
    flexDirection:'column',
    justifyContent:'center'
    
  },
  text:{
    position:'relative',fontSize:'30px', fontWeight: '700', textAlign:'center', color:'#fff', transform:'rotate(-15deg)'
  },
}));
