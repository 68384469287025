import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    finishDialogContainer: {
        backgroundColor: 'rgba(27, 34, 84, 0.9)',
    },
    finishDialogTitleContainer: {
        color: '#FFFFFF',
        backgroundColor: '#23445A',
    },
    finishDialogTitle: {
        fontSize: '1.8rem',
    },
    finishDialogSubTitle: {
        fontSize: '1rem',
        display: 'block',
        color: '#FFDB41',
        fontWeight: '600',
        textTransform: 'uppercase',
    },
    finishDialogContent: {
        color: '#23445A',
        fontWeight: '600',
        fontSize: '1.2rem',
    },
    finishDialogChip: {
        maxWidth: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    groupButton:{
        flexGrow: '1',
    },
    submitButton:{
        backgroundColor: '#FFDB40',
        color: '#23445A',
    },
    skipButton:{
        backgroundColor: '#718796',
        color: '#E5E5E5',
    },
}));
