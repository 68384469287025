import React, { useState } from 'react'
import { Card, Grid, CircularProgress, Typography, Button, TextField, InputAdornment, IconButton } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

// styles
import useStyles from './styles'

// import background from "/static/images/login.png";

// Icons
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

// context
import { loginUser, userStore } from '../../context/UserContext'

function Login(props) {
  var classes = useStyles()
  // local
  var [isLoading, setIsLoading] = useState(false)
  var [error, setError] = useState(null)
  var [loginValue, setLoginValue] = useState('@neuralmed.ai')
  var [passwordValue, setPasswordValue] = useState('')
  var [showPassword, setShowPassword] = useState(false)
  const setUser = userStore(state => state.setUser)
  const setAuthentication = userStore(state => state.setAuthentication)
  const setUserBilling = userStore(state => state.setUserBilling)
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer} />
      <div className={classes.formContainer}>
        <Card className={classes.form}>
          <div className={classes.titleContainer}>
            <Typography style={{ fontSize: '18px', fontWeight: '700' }}>Bem vindo a</Typography>
            <Typography style={{ fontSize: '60px', fontWeight: '700' }}>FLORENCE</Typography>
            <Typography style={{ fontSize: '18px', fontWeight: '700' }}>
              A primeira ferramenta de machine teaching medico
            </Typography>
          </div>

          <React.Fragment>
            <p style={{ textAlign: 'center', margin: 0 }}>
              {error ? 'Your login credentials could not be verified, please try again.' : null}
            </p>
            <div className={classes.inputsContainer}>
              <TextField
                id="email"
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                label="Email Address"
                placeholder="Email Address or Username"
                type="email"
                fullWidth
                variant="outlined"
              />
              <TextField
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                color="primary"
                variant="outlined"
              />
            </div>
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={loginValue.length === 0 || passwordValue.length === 0}
                  onClick={() => {
                    loginUser(loginValue, passwordValue, props.history, setIsLoading, setError, setUser, setAuthentication, setUserBilling, props)
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              )}
            </div>
          </React.Fragment>
          <p style={{ textAlign: 'center' }}>Powered by Neuralmed</p>
        </Card>
      </div>
    </Grid>
  )
}

export default withRouter(Login)
