import React from "react";
// components
import { Campaign } from "../../components/Campaign/Campaign";

export default function ValidatePage() {

  return (
    <Campaign style={{ minWidth: '100%', height: '512px', flex: '1' }}/>
  );
}
