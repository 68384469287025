import { AuthApi } from '../api';

function fetchNextExam(id) {
  return AuthApi.get(`/api/v1/cr/detail/`+id).then(response => response.data)
}

async function fetchUserPathologies({
  campaign_type,
}) {
  const response = await AuthApi.get(`/api/v1/users/pathologies`, {
    params: {
      ...(campaign_type && { campaign_type }),
    }
  })
  return response.data
}

function fetchUserInfo() {
  return AuthApi.post('/api/v1/users/me').then(response => response.data)
}
 
function fetchUserBilling() { 
  return AuthApi.get('/api/v1/users/billing-info').then(response => response.data)
}

function fetchValidateInfo(page, limit, modality, doctor) {
  return AuthApi.get('/api/v1/users/validate-info', {params: {modality: modality, page: page, limit: limit, doctor: doctor}}).then(response => response.data)
}

function fetchValidateInfoCampaign(campaign, modality) {
  return AuthApi.get('/api/v1/users/validate-info-campaign', {params: {modality: modality, campaign: campaign}}).then(response => response.data)
}

function fetchStudy(type, categoryId, id ) {
  return AuthApi.get(['/api/v1', type, 'detail', categoryId, id].join('/')).then(response => response.data)
}

function fetchNextExamId(type, categoryId) {
  return AuthApi.get(['/api/v1', type, 'next', categoryId].join('/')).then(response => response.data)
}

function countExamDone(type, categoryId) {
  return AuthApi.get(['/api/v1', type, 'count', categoryId].join('/')).then(response => response.data)
}

function postResults(data, type) {
  return AuthApi.post(['/api/v1', type, 'feedback'].join('/'),data)
}

function postValidation(data) {
  return AuthApi.post(['/api/v1', 'users', 'validation_feedback'].join('/'), data)
}

function postNewCampaign(data, bucketName) {
  return AuthApi.post(['/api/v1', 'pathologies', 'create-with-exams', bucketName].join('/'), data)
}

function fetchCampaigns() {
  return AuthApi.get('/api/v1/pathologies/list').then(response => response.data)
}

function putCampaign(data) {
  return AuthApi.put(['/api/v1', 'pathologies', 'update-campaign'].join('/'), data)
}

export const studyService = {
  fetchNextExam,
  fetchStudy,
  fetchNextExamId,
  postResults,
  countExamDone,
  fetchUserPathologies,
  fetchUserInfo,
  fetchUserBilling,
  fetchValidateInfo,
  postValidation,
  fetchValidateInfoCampaign,
  postNewCampaign,
  fetchCampaigns,
  putCampaign,
};
