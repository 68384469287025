import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
    sidebarContainer: {
        width: '100%',
        height: '100%',
        maxWidth: 360,
        backgroundColor: '#F4F7FE',
    },
    stepperContainer: {
        // paddingBottom: '10px', 
        // paddingTop: '10px',
        paddingLeft: '0px',
        paddingRight: '0px',
        height: 'inherit', 
        maxHeight: 'inherit',
        padding: '15px', 
        backgroundColor: '#F4F7FE',
    },
    tasksListItemHeader:{
        fontSize: '0.7rem',
        lineHeight: 1.1,
        color: '#25445C',
        fontWeight: '500',
        paddingRight: '10px',
        textTransform: 'uppercase',
    },
    tasksStepLabel:{
        flexDirection:'row-reverse',
        textAlign: 'right',
    },
    tasksActiveStepContent:{
        marginTop: '10px', 
        paddingLeft: '1px', 
        marginLeft: '0px', 
        borderLeft: '0px',
        textAlign: 'end',
    },
    tasksListItemSuccessLabel:{
        backgroundColor: 'rgb(140,233,21)',
        height: '10px',
        width: '10px',
        minWidth: '10px',
        fill: 'black',
    },
    tasksListItemSuccessIcon:{
        "& .MuiSvgIcon-root": {
            color: 'white',
        }
    },
    actionLoader:{
        height: '10px',
        width: '10px',
        marginRight: '10px',
    },
    groupButton:{
        color: '#FFFFFF',
        padding: '1px',
        margin: '1px',
        fontSize: '0.8rem',
    },
    submitButton:{
        backgroundColor: '#FFDB40',
        color: '#23445A',
    },
    skipButton:{
        backgroundColor: '#718796',
    },
    stepperProgress:{
        width: '100%',
    },
    bonusContainer:{
        marginTop: '-1vh',
        zIndex: '999',
        float: 'right',
    },
}));
