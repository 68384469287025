import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  statusSecondary: {
    minWidth: "30px",
  },
  statusItem: {
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  statusLabel: {
    color: "#FFFFFF",
    marginTop: '0px',
    marginBottom: '0px',
  },
  nextPaymentLabel: {
    textAlign: "right",
    "& .next-payment-value": {
      color: '#FFDB41',
      fontWeight: 700,
    }
  },
  statusAvatar: {
    width:'18px', 
    height:'18px',
    color: '#09DAC5',
    backgroundColor: '#23445A',
  },
}));
