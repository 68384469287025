import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import terms from './assets/docs/terms.pdf'
import './style.css'
import { Button, CircularProgress } from '@material-ui/core'
import { AuthApi } from '../../api'
import { signOut, userStore } from '../../context/UserContext'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  p: 4
}

export const GDPR = (props) => {
  const user = userStore(state => state.user)
  const [loading, setLoading] = useState(false)

  const handleAccept = async () => {
    try {
      setLoading(true)
      await AuthApi.put(`api/v1/users/gdpr-accept/${user.id}?gdpr_accept=true`)
      props.history.push('/app/dashboard')
    } catch (error) {
      signOut(props.history)
    }
  }

  const [accepted, setAccepted] = useState(false)
  return (
    <Modal
      open
      aria-labelledby="Termos e condições de uso do site"
    >
      <Box sx={style}>
        <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 text-center">
          Termos e condições de uso do site
        </h2>
        <div className="max-h-[500px] overflow-auto p-[16px] rounded-sm customScroll border border-[#B9B9B9]">
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            A A2 Tecnologia LTDA (“NeuralMed”) possui soluções que utilizam
            Inteligência Artificial para auxiliar nas tomadas de decisão do
            sistema de saúde. Desenvolvemos soluções inovadoras e extremamente
            poderosas, baseadas em algoritmos proprietários, maximizando todo o
            potencial das análises de imagens e textos médicos em tempo real.
            Este documento tem objetivo de detalhar as nossas políticas de
            coleta, uso e divulgação de informações, caso alguém decida usar
            nosso Serviço. Se você optar por usar nosso Serviço, você concorda
            com a coleta e uso de informações em relação a esta política.
          </p>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify mt-4">
            As informações que coletamos são usadas para fornecer e melhorar o
            serviço. Não usaremos ou compartilharemos suas informações com
            ninguém, exceto conforme descrito nesta Política de Privacidade. O
            desenvolvimento desta política foi estruturada de forma simples e
            acessível, para que você possa compreender como tratamos os dados,
            proporcionando assim uma experiência de transparência e segurança
            sobre as condutas da NeuralMed. Caso possua dúvidas relacionadas ao
            tratamento de seus dados pela NeuralMed, pedimos que entre em
            contato através do nosso Encarregado pelo Tratamento de Dados,
            através dos canais de comunicação aqui especificados.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Coleta e uso de informações
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Para uma melhor experiência, ao usar nosso Serviço, podemos exigir
            que você nos forneça certas informações não pessoalmente
            identificáveis. As informações que solicitamos serão retidas por nós
            e usadas conforme descrito nesta política de privacidade, utilizando
            o mínimo de informações necessárias para as finalidades voltadas à
            execução dos nossos serviços.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Dados de registro
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Queremos informá-lo que sempre que você utilizar nosso Serviço, em
            caso de erro na aplicação, coletamos dados e informações (por meio
            de produtos de terceiros). Estes dados de registro podem incluir
            informações como endereço de protocolo de Internet (&quot;IP&quot;)
            do dispositivo, nome do dispositivo, versão do sistema operacional,
            nome e versão do navegador de Internet, hora e data de uso do
            serviço e outras estatísticas. É importante reforçar que nossas
            soluções apenas operacionalizam as decisões de tratamento feitas
            pelos clientes. Portanto, não exercemos a atividade de controlador
            sobre os dados pessoais tratados através dos nossos sistemas. As
            informações coletadas e processadas pelos nossos softwares são
            controladas por nossos clientes, que as utilizam, divulgam e
            protegem, de acordo com suas respectivas políticas de privacidade e
            proteção de dados.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Cookies
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Cookies são arquivos com uma pequena quantidade de dados que são
            comumente usados como identificadores exclusivos anônimos. Eles são
            enviados para o seu navegador a partir dos sites que você visita e
            são armazenados na memória interna do seu dispositivo. Este Serviço
            não usa esses cookies explicitamente. No entanto, o aplicativo pode
            usar código de terceiros e bibliotecas que usam cookies para coletar
            informações e melhorar seus serviços. Você tem a opção de aceitar ou
            recusar esses cookies e saber quando um cookie está sendo enviado
            para o seu dispositivo. Se você optar por recusar nossos cookies,
            pode não ser capaz de usar algumas partes deste serviço.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Links para outros sites
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Este serviço pode conter links para outros sites. Se você clicar em
            um link de terceiros, será direcionado a esse site. Observe que
            esses sites externos não são operados por nós. Portanto,
            recomendamos enfaticamente que você analise a Política de
            Privacidade desses sites. Não temos controle e não assumimos
            responsabilidade pelo conteúdo, políticas de privacidade ou práticas
            de quaisquer sites ou serviços de terceiros.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Privacidade para crianças
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Estes serviços não se dirigem a ninguém com idade inferior a 13
            anos. Não recolhemos intencionalmente informações de identificação
            pessoal de crianças com menos de 13 anos. No caso de descobrirmos
            que uma criança com menos de 13 anos nos forneceu informações
            pessoais, as eliminamos imediatamente dos nossos servidores. Se você
            é um pai ou responsável e sabe que seu filho nos forneceu
            informações pessoais, entre em contato para que possamos tomar as
            medidas necessárias.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Como protegemos os dados
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Com a finalidade de prover a segurança dos dados recebidos e
            processados, todas as atividades realizadas pela NeuralMed contam
            com medidas e mecanismos de controle, técnicas e metodologias
            compatíveis aos padrões de mercado para fornecer a integridade e
            confidencialidade do uso correto e legal dos dados que são
            fornecidos e submetidos. Além disso, adotamos diversas medidas
            preventivas para minimizar e evitar a ocorrência de qualquer dano
            envolvendo os dados operacionalizados pela empresa.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Retenção e descarte de dados
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Ao coletar, utilizar e manter Dados Pessoais no contexto da sua
            operação, a NeuralMed assume responsabilidade sobre o conjunto de
            Dados Pessoais tratados, devendo estabelecer procedimentos internos
            capazes de conferir nível adequado de proteção aos Dados Pessoais de
            forma aderente à Legislação Aplicável. Uma das responsabilidades da
            NeuralMed se refere à necessidade de estabelecer efetiva governança
            sobre o conjunto de Dados Pessoais por ela mantido, estipulando os
            limites aplicáveis à forma de utilização de tais Dados Pessoais, os
            prazos pelos quais eles podem ser retidos, bem como as situações que
            exigem o seu descarte. A Legislação Aplicável estabelece parâmetros
            sobre a necessária indicação do tempo de armazenamento dos Dados
            Pessoais coletados e dos mecanismos de descarte deles. Em alguns
            casos, a exclusão definitiva dos Dados Pessoais é um direito
            assegurado aos Titulares e deve ser observado pela NeuralMed. As
            orientações previstas nesta Política têm como principais objetivos:
            (i) assegurar que os Dados Pessoais sejam armazenados e descartados
            em período adequado, observado o tempo necessário para o cumprimento
            de sua finalidade; (ii) minimizar a retenção desnecessária de Dados
            Pessoais; (iii) garantir que os registros referentes à retenção e ao
            descarte de Dados Pessoais sejam eficientes, apropriados e
            devidamente armazenados. A retenção de Dados Pessoais é permitida
            nas seguintes hipóteses: (i) quando há uma base legal válida e
            adequada para determinada finalidade legítima de tratamento,
            inclusive quando os Dados Pessoais forem necessários para o
            cumprimento de obrigação legal ou regulatória pela NeuralMed; (ii)
            quando os Dados Pessoais forem submetidos a processo de
            anonimização, por meio do qual não seja possível a reidentificação
            do seu Titular.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Mudanças nesta Política de Privacidade
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            Podemos atualizar nossa Política de Privacidade de tempos em tempos.
            Portanto, é recomendável revisar esta página periodicamente para
            verificar quaisquer alterações. Iremos notificá-lo de quaisquer
            alterações, publicando a nova Política de Privacidade nesta página.
            Esta política está em vigor desde 01/01/2021.
          </p>
          <h2 className="font-epilogue font-bold text-[#464646] text-[22px] leading-5 mb-4 mt-9">
            Encarregado pelo tratamento dos dados pessoais
          </h2>
          <p className="font-epilogue font-regular text-[#464646] text-[18px] leading-6 indent-xl text-justify">
            A NeuralMed poderá atuar na qualidade controladora e operadora de
            dados, quando assim submetida, comprometendo-se a respeitar e a
            cumprir a legislação, bem como os termos propostos nesta Política de
            Privacidade e Proteção de Dados. Para prover a comunicação entre o
            titular de dados e a NeuralMed, nomeamos um Encarregado pelo
            Tratamento de Dados (DPO), que possui a responsabilidade de
            supervisionar todas as questões relacionadas a esta Política de
            Privacidade e Proteção de Dados. Se suas informações pessoais foram
            usadas de maneira incompatível com esta Política, ou se você tiver
            dúvidas, comentários ou sugestões, pode entrar em contato com nosso
            Encarregado pelo Tratamento de Dados através do e-mail::
            dpo@neuralmed.ai
          </p>
        </div>
        <div className="m-[16px] flex items-center gap-1">
          <input
            type="checkbox"
            checked={accepted}
            onChange={() => setAccepted(prevState => !prevState)}
          />
          <p className="font-epilogue font-regular text-[#464646] mt-[2px]">
            Li e estou de acordo com o{' '}
            <a
              href={terms}
              download="Termo de Uso e Politica de Privacidade"
              className="text-[#1A73E8]"
            >
              Termo de Uso e Politica de Privacidade
            </a>
          </p>
        </div>
        <Button
          disabled={!accepted}
          variant="contained"
          color="primary"
          onClick={handleAccept}
          className="my-[10px] w-[230px] h-[50px]"
          style={{
            display: 'block',
            margin: 'auto'	
          }}
        >
           {loading ? <CircularProgress size={24} /> : 'Continuar'}
        </Button>
      </Box>
    </Modal>
  )
}
