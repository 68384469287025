import React, {useState} from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom'
import {
  Paper,
  Grid,
  Modal,
} from '@material-ui/core';

import {FormCampaign} from '../CampaignForm/CampaignForm' 

import { studyService } from '../../services/studies';

import MaterialTable from 'material-table';

export const Campaign = props => {

  const [open, setOpen] = React.useState(false);
  const [campaign, setCampaign] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let history = useHistory()

  const parseTableFormat = (response) => {
    var campaignArray = []
    response.map(item => {
        campaignArray.push(
          {
            'campaignName':item.name,
            'campaignId':item.id,
            'date':item.created,
            'bucketName':item.bucket,
            'status':item.status,
            'description':item.description,
            'modality':item.modality,
            'subTitle':item.sub_title,
            'bodyPart':item.body_part,
            'campaignType':item.campaign_type,
            'doctor':item.user_id,
            'originalPathologyList':item.pathology_list,
            'pathologyList': item.pathology_list.reduce(function(r, e){ 
              r.push(`${e['label']}, `); 
              return r
            }, []),
          }
        )
        setCampaign(campaignArray)
    });
    return campaignArray
  };

  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <FormCampaign type = "create"/>
        </Modal>
        
          <Paper square style={{ height: '100%' }}>
            <MaterialTable style={{ paddingLeft: "10px" }}
              localization={{header: {actions: ''}}}
              title={''}
              columns={[
                { title: 'Nome da Campanha', 
                  field: 'campaignName', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, 
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'Lista de Patologias', 
                  field: 'pathologyList', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, 
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'Nome do Bucket', 
                  field: 'bucketName', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, defaultSort: 'desc',
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'Data de Criação', 
                  field: 'date',
                  render: rowData => moment(rowData.date).format('DD/MM/YYYY'),
                  headerStyle: {textAlign:'left', fontSize:'12px'},
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'Status', 
                  field: 'status', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, defaultSort: 'desc',
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  studyService.fetchCampaigns().then((result) => {
                    resolve({
                      data: parseTableFormat(result),
                    })
                  }).catch(err => {
                    console.log(err)
                    history.push('/app/dashboard')
                  })
                })
              }
              actions={[
                {
                  icon: "add_box",
                  tooltip: "Adicionar Estudo",
                  position: "toolbar",
                  onClick: () => {
                    handleOpen()
                  }
                }
              ]}
              options={{
                search: false,
                sorting: false,
                pageSize: 30,
                padding: 'dense',
                toolbar: true,
              }}
              onRowClick={(event, rowData, togglePanel) => {togglePanel();}}
              detailPanel={[
                {
                  render: rowData => {
                    return (
                      <FormCampaign type = "modify" rowData={rowData}/>
                    )
                  },
                },
              ]}
            />
          </Paper>
        </Grid>
    </Grid>
  );
}
export default Campaign;
