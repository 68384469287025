import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@mui/material/Box'

export const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        maxWidth: '100vw',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1000',
        backgroundColor: '#fff'
      }}
    >
      <CircularProgress size={120} />
    </Box>
  )
}
