import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  progressVertical: {
    backgroundColor: 'rgb(188,240,240)',
    borderRadius: '3px',
    boxShadow: 'none',
    position: 'absolute',
    width: '10px',
    height: '75vh',
    display: 'inline-block',
    marginRight: '5px',
    // paddingTop: '15px',
  },
  progressBar: {
    backgroundColor: 'rgb(12,218,197)',
    borderRadius: '3px',
    boxShadow: 'none',
    width: '100% !important',
    position: 'absolute',
    top: '0',
 }
}));
