import React from "react";
import { useParams } from 'react-router-dom'
// components
import { Viewport } from "../../components/Viewport/Viewport";
import { Nlp } from "../../components/Nlp/Nlp";

export default function StudyPage() {
  let { category } = useParams();
  const renderCatogeryComponent = () => {
    if (category === 'nlp') {
      return <Nlp sidebar={false} style={{ minWidth: '100%', height: '512px', flex: '1' }}/>;
    }
    if (category === 'cr' || category === 'ct' || category === 'ct-class') {
      return <Viewport sidebar={false} style={{ minWidth: '100%', height: '512px', flex: '1' }} isClassification={category.includes('-class')}/>;
    }
  }

  return (
    renderCatogeryComponent()
  );
}
