import React from "react";
import {
  AppBar,
  Toolbar,
  LinearProgress,
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";

// styles
import useStyles from "./styles";


// image
import {NumericalBadge} from "../../../../components/Wrappers";

// context
import { userStore } from "../../../../context/UserContext";

export default function ProgressHeader(props) {
  const {monthlyProgress, monthlyGoal} = props;
  const user = userStore.getState().user
  var classes = useStyles();

  const normalize = (progress, goal) => Math.min(100,(monthlyProgress/monthlyGoal)*100);

  // global
  const userBilling = userStore(state => state.userBilling);

  const progressionTheme = createMuiTheme({
    palette: {
       primary: {
        main: "#255A23"
       }
    }
  })

  return (
    <AppBar position="static" className={classes.appBar}  >
      <Toolbar className={classes.progressToolBar} >
        <NumericalBadge headerText={'Exames Completos'} totalValue={userBilling.examsCompleted} isNotMoney/>
      </Toolbar >
    </AppBar>
  );
}
