import React from "react";
// styles
import useStyles from "./styles";

export default function ComingSoonOverlay() {
  
  var classes = useStyles();
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (!isSafari){
    return (
      <div className={classes.overlay}>
        <p className={classes.text}>EM BREVE</p>
      </div>
    );
  }
  else{
    return(<div></div>);
  }
}


