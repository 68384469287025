import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    sidebarContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: '#F4F7FE',
    },
    sidebarHeader: {
        justifyContent: 'left',
        paddingLeft: '20px',
        paddingTop: '10px',
        borderRadius: 0,
        borderRight: '0',
        backgroundColor: '#FFFFFF',
        color: '#23445ADE',
        height: '50px',
    },
    sidebarHeaderText: {
        lineHeight: '2',
        fontWeight: '500',
        color: '#718796',
    },
    sidebarHeaderAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: '#718796',
        color: '#FFFFFF',
    },
    sidebarHeaderAvatarRoot: {
        display: 'flex',
        '& > *': {
        marginRight: '10px',
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    classificationButtonGroup: {
        display: 'flex',
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    classificationButton: {
        justifyContent: 'left',
        padding: '10px',
        paddingLeft: '20px',
        borderRadius: 0,
        border: '0px',
    },
    classificationButtonLabel: {
        textAlign: 'left',
        textTransform: 'capitalize',
        color: '#25445C',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
    },
    classificationButtonSubLabel: {
        textAlign: 'left',
        textTransform: 'uppercase',
        display: 'block',
        fontSize: '10px',
        fontWeight: 'lighter',
    },
}));
