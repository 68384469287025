import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'

import Themes from './themes'
import App from './components/App'
import initCornerstone from './initCornerstone.js'
import { LayoutProvider } from './context/LayoutContext'
import { BrowserRouter } from 'react-router-dom'
import { ConfirmProvider } from 'material-ui-confirm'

initCornerstone()
ReactDOM.render(
  <LayoutProvider>
    <ThemeProvider theme={Themes.default}>
      <CssBaseline />
      <BrowserRouter>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </BrowserRouter>
    </ThemeProvider>
  </LayoutProvider>,
  document.getElementById('root')
)
