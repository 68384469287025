import React, { useState, useEffect } from 'react'
import CornerstoneViewport from 'react-cornerstone-viewport'
import { useParams, useHistory } from 'react-router-dom'
import cornerstoneTools from 'cornerstone-tools'
import cornerstone from 'cornerstone-core'
import {
  ButtonGroup,
  Button,
  Grid,
  Chip,
  Paper,
  Tooltip,
  Box,
  withStyles,
  Snackbar
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import MuiAlert from '@material-ui/lab/Alert'
import ViewportSidebar from '../ViewportSidebar/ViewportSidebar'
import ViewportSidebarConfirmation from '../ViewportSidebarConfirmation/ViewportSidebarConfirmation'
import { NumericalBadge } from '../Wrappers'
import AssignmentLate from '@material-ui/icons/AssignmentLate'
import CloseIcon from '@material-ui/icons/Close'
import InvertColorsOff from '@material-ui/icons/InvertColorsOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { v4 as uuidv4 } from 'uuid'
import LoadingOverlay from 'react-loading-overlay'
import Loader from 'react-loader-spinner'
import Hotkeys from 'react-hot-keys'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

import { studyService } from '../../services/studies'
import { userStore } from '../../context/UserContext'
import { cornerstoneConfig } from './data'
// styles
import useStyles from './styles'

function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />
}

function AlertError(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
      style={{ backgroundColor: '#ff6b6b' }}
    />
  )
}

function CustomOverlay(props) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '7vh',
        left: '15px',
        width: '100%',
        height: '100%',
        color: 'white'
      }}
    >
      <div>
        <span style={{ color: 'white' }}>
          {props.imageIndex}/{props.stackSize}
        </span>
      </div>
      <div>
        <span style={{ color: 'white' }}>
          WW/WC:{Math.round(props.windowWidth)}/{Math.round(props.windowCenter)}
        </span>
      </div>
    </div>
  )
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFF'
    }
  }
})

const DRAW_LABEL_TYPE = ['FreehandRoi', 'RectangleRoi']
const SLICE_LABEL_TYPE = ['NoFinding', 'BadQuality']
const SINGLE_LABEL_TYPE = ['AddLabel']
const VOI_PRESETS = [
  {
    name: 'CHEST',
    label: 'CHEST',
    windowWidth: 1600,
    windowCenter: -600
  },
  {
    name: 'SKULL',
    label: 'BRAIN',
    windowWidth: 80,
    windowCenter: 40
  },
  {
    name: 'BONE',
    label: 'BONE',
    windowWidth: 2800,
    windowCenter: 600
  },
  {
    name: 'BODY',
    label: 'BODY',
    windowWidth: 350,
    windowCenter: 50
  }
]

export const Viewport = ({ className, isClassification, ...rest }) => {
  const classes = useStyles()
  const user = userStore.getState().user
  let { id, category } = useParams()
  let history = useHistory()
  const userBilling = userStore.getState().userBilling

  const [activeTool, setActiveTool] = useState('')
  const [campaignType, setCampaignType] = useState('')
  const [activeCursor, setActiveCursor] = useState('')
  const [selectedBtn, setSelectedBtn] = useState(-1)
  const [invertBtn, setInvertBtn] = useState(-1)
  const [activePathology, setActivePathology] = useState('')
  const [activeLabelType, setActiveLabelType] = useState('')
  const [activeImage, setActiveImage] = useState(0)
  const [selectedSeries, setSelectedSeries] = useState(0)
  const [activePathologyColor, setActivePathologyColor] = useState('')
  const [roiArray, setRoiArray] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const [studyId, setStudyId] = useState('')
  const [nextStudyId, setNextStudyId] = useState('')
  const [nextStudyDetails, setNextStudyDetails] = useState('')

  const [viewport, setViewport] = useState(cornerstoneConfig)
  const [cornerstoneElement, setCornerstoneElement] = useState()
  const [preset, setPreset] = useState('')

  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')
  cornerstoneTools.toolStyle.setToolWidth(5)

  const invertDicomImage = (event, newPreset) => {
    setInvertBtn(-1 * invertBtn)
    cornerstoneElement.viewport.invert = !cornerstoneElement.viewport.invert
    cornerstone.updateImage(cornerstoneElement.element)
  }

  const parseStudyDetails = response => {
    var seriesArray = []
    response.series.map(item => {
      seriesArray.push({
        seriesId: item.seriesId,
        exams: item.exams,
        thumbnail: item.thumbnail,
        description: item.description,
        imageSet: item.imageSet.map(function (value) {
          return value.replace('https', 'wadouri')
        })
      })
    })
    return seriesArray
  }

  useEffect(() => {
    if (activePathology) {
      let activeClassification = viewport.classifications.find(
        x => x.label === activePathology
      )
      setActivePathologyColor(activeClassification.color)
      setActiveLabelType(activeClassification.type)
    }
  }, [activePathology])

  useEffect(() => {
    if (nextStudyId) {
      studyService
        .fetchStudy(category.replace('-class', ''), id, nextStudyId)
        .then(response => {
          setNextStudyDetails(parseStudyDetails(response))
        })
    }
  }, [nextStudyId])

  useEffect(() => {
    if (isSubmit) {
      setIsLoaded(false)
      setStudyId(nextStudyId)
      setNextStudyId('')
      roiArray
        .filter(roi => roi.data.element !== undefined)
        .map(roi => {
          if (
            cornerstoneTools.getToolState(
              roi.data.element,
              roi.data.toolName
            ) !== undefined
          ) {
            cornerstoneTools.removeToolState(
              roi.data.element,
              roi.data.toolName,
              cornerstoneTools
                .getToolState(roi.data.element, roi.data.toolName)
                .data.filter(
                  item => item.uuid === roi.data.measurementData.uuid
                )[0]
            )
            cornerstone.updateImage(roi.data.element)
          }
        })
      setRoiArray([])
      setActivePathology('')
      setActiveTool('')
      setActiveCursor('')
      setActivePathologyColor('')
      setActiveImage(0)
      setViewport({ ...viewport, series: nextStudyDetails })
    }
    if (!isSubmit && nextStudyId !== studyId) {
      setStudyId(nextStudyId)
      setNextStudyDetails([])
    }
  }, [isSubmit])

  useEffect(() => {
    if (studyId === nextStudyId) {
      cornerstone.imageCache.purgeCache()
      studyService
        .fetchNextExamId(category.replace('-class', ''), id)
        .then(response => {
          if (response === undefined) {
            history.push('/app/dashboard')
          }
          setNextStudyId(response[1])
          setStudyId(response[0])
          if (
            response !== undefined &&
            viewport.series[0].imageSet[0] ===
              cornerstoneConfig.series[0].imageSet[0]
          ) {
            studyService
              .fetchStudy(category.replace('-class', ''), id, response[0])
              .then(data => {
                setViewport({
                  ...viewport,
                  series: parseStudyDetails(data),
                  classifications: data.pathologyList,
                  body_part: data.body_part
                })
                setCampaignType(
                  data.series[0].exams[0].classifications.campaign_type
                )
              })
          }
        })
        .catch(err => {
          console.log(err)
          setAlertText('Campanha finaizada.')
          setShowAlert(true)
          window.setTimeout(() => {
            history.push('/app/dashboard')
          }, 3000)
        })
      return () => {}
    }
  }, [studyId])

  useEffect(() => {
    let label = viewport.classifications.find(x => x.label === activePathology)

    if (label !== undefined && SLICE_LABEL_TYPE.includes(label.type)) {
      addClassification({}, 'SLICE')
    }
    if (label !== undefined && SINGLE_LABEL_TYPE.includes(label.type)) {
      addClassification({}, 'SLICE')
    }
  }, [activePathologyColor])

  useEffect(() => {
    if (isLoaded) {
      handlePreset(undefined, viewport.body_part)
    }
  }, [isLoaded])

  // Useeffect to detect when user leaves the page, if its in the middle of CT images loading, will prevent error
  useEffect(() => {
    return () => {
      window.location.reload()
    }
  }, [])

  const handlePreset = (event, newPreset) => {
    if (!newPreset) {
      return
    }
    const voi = VOI_PRESETS.find(item => item.name === newPreset)
    cornerstoneElement.viewport.voi.windowWidth = voi.windowWidth
    cornerstoneElement.viewport.voi.windowCenter = voi.windowCenter
    cornerstone.updateImage(cornerstoneElement.element)
    setPreset(newPreset)
  }

  const getButtonToolbar = viewport.toolbarTools.map((button, key) => (
    <Tooltip title={button.name}>
      <Button
        color={selectedBtn === key ? 'secondary' : 'primary'}
        className={classes.toolsMenuButton}
        onClick={() => {
          setActiveTool(button.name)
          setActiveCursor(button.cursor)
          setSelectedBtn(key)
        }}
        key={key}
        value={button.name}
        disabled={activePathology != ''}
      >
        {button.icon}
      </Button>
    </Tooltip>
  ))

  const addClassification = (event, type) => {
    if (
      ((SLICE_LABEL_TYPE.includes(activeLabelType) &&
        roiArray.some(item => DRAW_LABEL_TYPE.includes(item.label_type))) ||
        roiArray.some(item => SLICE_LABEL_TYPE.includes(item.label_type))) &&
      ['cr'].includes(category)
    ) {
      return
    }
    if (
      SLICE_LABEL_TYPE.includes(activeLabelType) &&
      roiArray.some(item => DRAW_LABEL_TYPE.includes(item.label_type))
    ) {
      return
    }
    let currentImage = roiArray.filter(item => item.imageIdIndex == activeImage)
    if (
      currentImage.filter(item => SLICE_LABEL_TYPE.includes(item.label_type))
        .length <= 0
    ) {
      switch (type) {
        case 'ROI':
          if (activePathology === '') {
            cornerstoneTools.removeToolState(
              event.detail.element,
              event.detail.toolName,
              cornerstoneTools
                .getToolState(event.detail.element, event.detail.toolName)
                .data.filter(
                  item => item.uuid === event.detail.measurementData.uuid
                )[0]
            )

            cornerstone.updateImage(event.detail.element)
            break
          }
          if (
            roiArray.filter(
              item =>
                item.data.measurementData.uuid !== undefined &&
                item.data.measurementData.uuid ===
                  event.detail.measurementData.uuid
            ).length == 0
          ) {
            event.detail.measurementData.handles.textBox.freehand = true
            event.detail.measurementData.color = activePathologyColor
            let exam = viewport.series[selectedSeries].exams[activeImage]
            setRoiArray(roiArray => [
              ...roiArray,
              {
                id: uuidv4(),
                data: event.detail,
                class: activePathology,
                color: activePathologyColor,
                selectedSeries: selectedSeries,
                imageIdIndex: activeImage,
                type: type,
                exam_id: exam.id,
                study_instance_uid: exam.study_instance_uid,
                serie_instance_uid: exam.serie_instance_uid,
                sop_instance_uid: exam.sop_instance_uid,
                label_type: activeLabelType
              }
            ])
          }
          break
        case 'SLICE':
          if (
            activePathology === '' ||
            (!isClassification && DRAW_LABEL_TYPE.includes(activeTool))
          )
            return
          if (SLICE_LABEL_TYPE.includes(activeLabelType)) {
            let examList = viewport.series[selectedSeries].exams
            examList.forEach(function (exam, index) {
              setRoiArray(roiArray => [
                ...roiArray,
                {
                  id: uuidv4(),
                  data: { measurementData: { uuid: uuidv4() } },
                  class: activePathology,
                  color: activePathologyColor,
                  imageIdIndex: index,
                  type: type,
                  exam_id: exam.id,
                  study_instance_uid: exam.study_instance_uid,
                  serie_instance_uid: exam.serie_instance_uid,
                  sop_instance_uid: exam.sop_instance_uid,
                  label_type: activeLabelType
                }
              ])
            })
          } else {
            let exam = viewport.series[selectedSeries].exams[activeImage]
            setRoiArray(roiArray => [
              ...roiArray,
              {
                id: uuidv4(),
                data: { measurementData: { uuid: uuidv4() } },
                class: activePathology,
                color: activePathologyColor,
                imageIdIndex: activeImage,
                type: type,
                exam_id: exam.id,
                study_instance_uid: exam.study_instance_uid,
                serie_instance_uid: exam.serie_instance_uid,
                sop_instance_uid: exam.sop_instance_uid,
                label_type: activeLabelType
              }
            ])
          }

          break
        default:
          return
      }
    }
  }

  const removeClassification = event => {
    if (['SLICE', 'SERIES'].includes(event.type)) {
      setRoiArray(
        roiArray.filter(
          item =>
            item.data.measurementData.uuid !== event.data.measurementData.uuid
        )
      )
      return
    }

    setRoiArray(
      roiArray.filter(
        item =>
          item.data.measurementData.uuid !== event.data.measurementData.uuid
      )
    )
    cornerstoneTools.removeToolState(
      event.data.element,
      event.data.toolName,
      cornerstoneTools
        .getToolState(event.data.element, event.data.toolName)
        .data.filter(item => item.uuid === event.data.measurementData.uuid)[0]
    )

    cornerstone.updateImage(event.data.element)
  }

  const highlightClassification = event => {
    if (['SLICE', 'SERIES'].includes(event.type)) {
      setRoiArray(
        roiArray.filter(
          item =>
            item.data.measurementData.uuid !== event.data.measurementData.uuid
        )
      )
      return
    }

    const toolState = cornerstoneTools.getToolState(
      event.data.element,
      event.data.toolName
    ).data
    toolState.map(obj => (obj.active = false))
    toolState.filter(
      item => item.uuid === event.data.measurementData.uuid
    )[0].active = true
    cornerstone.updateImage(event.data.element)
  }

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }))(Tooltip)

  const handleKeyboardEvents = event => {
    switch (event) {
      case 'down':
        setActiveImage(
          Math.min(viewport.series[0].imageSet.length - 1, activeImage + 1)
        )
        break
      case 'up':
        setActiveImage(Math.max(0, activeImage - 1))
        break
      case 'shift+s':
        let currentSlice = roiArray.filter(
          item =>
            item.imageIdIndex === activeImage &&
            item.class === activePathology &&
            item.type === 'SLICE'
        )
        if (currentSlice.length === 0) {
          addClassification({}, 'SLICE')
        } else {
          removeClassification(currentSlice[0])
        }
        break
      default:
        return
    }
  }

  return (
    <Hotkeys
      keyName="shift+s,down,up"
      onKeyDown={evt => {
        handleKeyboardEvents(evt)
      }}
      style={{ cursor: 'move' }}
    >
      <Grid container style={{ height: '100%' }}>
        <Snackbar open={showAlert} autoHideDuration={3000}>
          <AlertError severity="error">{alertText}</AlertError>
        </Snackbar>
        <Grid item xs={12}>
          <Paper square style={{ height: '100%' }}>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="space-between"
              style={{ height: '100%' }}
            >
              <Grid item xs={2}>
                <ViewportSidebar
                  viewport={viewport}
                  isClassification={isClassification}
                  setActivePathology={setActivePathology}
                  setActiveTool={setActiveTool}
                  isSubmit={isSubmit}
                  campaignType={campaignType}
                  addClassification={addClassification}
                />
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  backgroundColor: 'black',
                  width: '100%',
                  height: '100%'
                }}
              >
                <Paper elevation={0}>
                  <Grid container className={classes.toolsMenuContainer}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ width: '100%' }}
                    >
                      <Box style={{ marginLeft: '10vh' }}>
                        <ThemeProvider theme={theme}>
                          <ButtonGroup
                            disableElevation
                            disableRipple={true}
                            size="small"
                            variant="contained"
                          >
                            {getButtonToolbar}
                            <Button
                              color={invertBtn === 1 ? 'secondary' : 'primary'}
                              className={classes.toolsMenuButton}
                              onClick={() => {
                                invertDicomImage()
                              }}
                            >
                              {<InvertColorsOff />}
                            </Button>
                          </ButtonGroup>
                          <ToggleButtonGroup
                            style={{
                              display: ['cr'].includes(category)
                                ? 'none'
                                : 'block'
                            }}
                            size="small"
                            value={preset}
                            exclusive
                            onChange={handlePreset}
                            className={classes.presetButtonGroup}
                          >
                            {VOI_PRESETS.map((button, key) => {
                              return (
                                <ToggleButton
                                  key={key}
                                  value={button.name}
                                  size="small"
                                >
                                  {button.label}
                                </ToggleButton>
                              )
                            })}
                          </ToggleButtonGroup>
                        </ThemeProvider>
                      </Box>
                      <Box
                        style={{
                          marginLeft: 'auto',
                          paddingRight: 10,
                          color: '#214459'
                        }}
                      >
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Teclas rápidas
                              </Typography>
                              <p></p>
                              <b>{'shift + e'}</b> {'=>'} {'Submit Feedback'}{' '}
                              <p></p>
                              <b>{'shift + space'}</b> {'=>'} {'SKIP'} <p></p>
                              <b>{'shift + s'}</b> {'=>'} {'Adicionar Label'}{' '}
                              <p></p>
                              {/* <b>{"shift + 1"}</b> {'=>'} {''}{' '}<p></p> */}
                              <b>{'UP'}</b> {'=>'} {'Slice Anterior'} <p></p>
                              <b>{'DOWN'}</b> {'=>'} {'Próximo Slice'} <p></p>
                            </React.Fragment>
                          }
                        >
                          <AssignmentLate />
                        </HtmlTooltip>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    style={{ backgroundColor: 'black', width: '100%' }}
                    direction="column"
                  >
                    <Grid item xs={12} style={{ backgroundColor: 'black' }}>
                      <LoadingOverlay
                        active={!isLoaded}
                        styles={{
                          overlay: base => ({ ...base, background: 'black' })
                        }}
                        spinner={
                          <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={100}
                          />
                        }
                      >
                        {viewport.series[0].imageSet.length > 0 && (
                        <CornerstoneViewport
                        tools={viewport.tools}
                        imageIds={viewport.series[0].imageSet}
                        isStackPrefetchEnabled={true}
                        onElementEnabled={elementEnabledEvt => {
                          setCornerstoneElement(elementEnabledEvt.detail)
                        }}
                        //isOverlayVisible={true}
                        viewportOverlayComponent={CustomOverlay}
                        activeTool={
                          roiArray.filter(item => item.class === 'Normal')
                            .length == 0 &&
                          roiArray.filter(
                            item => item.class === 'Imagem inadequada'
                          ).length == 0
                            ? activeTool
                            : null
                        }
                        imageIdIndex={activeImage}
                        style={{
                          minWidth: '100%',
                          height: 0.85 * window.innerHeight + 'px',
                          flex: '1',
                          cursor:
                            activePathology === '' ||
                            activePathology === 'Normal' ||
                            activePathology === 'Imagem inadequada'
                              ? activeCursor
                              : 'cell'
                        }}
                        eventListeners={[
                          {
                            target: 'cornerstone',
                            eventName: 'cornerstonenewimage',
                            handler: evt => {
                              cornerstoneConfig.imageIds[0] !==
                                evt.detail.image.imageId && setIsLoaded(true)
                            }
                          },
                          {
                            target: 'cornerstone',
                            eventName: 'cornerstonetoolsmeasurementcompleted',
                            handler: evt => {
                              addClassification(evt, 'ROI')
                            }
                          },
                          {
                            target: 'cornerstone',
                            eventName: 'cornerstonetoolsmousedoubleclick',
                            handler: evt => {
                              if (isClassification) {
                                let currentSlice = roiArray.filter(
                                  item =>
                                    item.imageIdIndex === activeImage &&
                                    item.class === activePathology &&
                                    item.type === 'SLICE'
                                )
                                if (currentSlice.length === 0) {
                                  addClassification({}, 'SLICE')
                                } else {
                                  removeClassification(currentSlice[0])
                                }
                              }
                            }
                          },

                          {
                            target: 'cornerstone',
                            eventName: 'cornerstonetoolsmeasurementremoved',
                            handler: evt => {
                              setRoiArray(
                                roiArray.filter(
                                  item =>
                                    item.data.measurementData.uuid !==
                                    evt.detail.measurementData.uuid
                                )
                              )
                            }
                          },
                          {
                            target: 'cornerstone',
                            eventName:
                              'cornerstonetoolsstackprefetchimageloaded',
                            handler: evt => {
                              if (preset && isLoaded) {
                                handlePreset(undefined, preset)
                              }
                            }
                          },

                          {
                            target: 'cornerstone',
                            eventName: 'cornerstonetoolsstackscroll',
                            handler: evt => {
                              setActiveImage(evt.detail.newImageIdIndex)
                            }
                          }
                        ]}
                      >
                        <Alert
                          severity=""
                          className={classes.alert}
                          style={{
                            backgroundColor: activePathologyColor,
                            visibility:
                              activePathology && !['cr'].includes(category)
                                ? 'visible'
                                : 'hidden'
                          }}
                          action={
                            <ButtonGroup
                              color="primary"
                              aria-label="outlined primary button group"
                            >
                              <Button
                                size="small"
                                onClick={() => {
                                  setRoiArray(
                                    roiArray.filter(
                                      item => item.class != activePathology
                                    )
                                  )
                                }}
                                className={classes.alertClose}
                                style={{
                                  color: activePathologyColor,
                                  borderRadius: '7px',
                                  marginRight: 10,
                                  visibility:
                                    activePathology &&
                                    !['cr'].includes(category)
                                      ? 'visible'
                                      : 'hidden'
                                }}
                              >
                                REMOVER DE TODOS OS SLICES
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  setActivePathology('')
                                  setActiveTool('')
                                }}
                                className={classes.alertClose}
                                style={{
                                  color: activePathologyColor,
                                  borderRadius: '7px'
                                }}
                              >
                                CANCEL
                              </Button>
                            </ButtonGroup>
                          }
                        >
                          <Chip
                            label={'ACTIVE | ' + activePathology}
                            className={classes.alertLabel}
                          />
                          <Chip
                            className={classes.alertLabel}
                            label={
                              isClassification
                                ? 'Double click to add classification'
                                : 'Draw a Shape Around the Affected Area'
                            }
                          />
                        </Alert>
                        <div
                          className={classes.chipContainer}
                          style={{
                            display: roiArray.length === 0 ? 'none' : 'block'
                          }}
                        >
                          {roiArray.map((roi, key) => (
                            <Chip
                              key={key}
                              style={{ backgroundColor: roi.color }}
                              className={
                                roi.imageIdIndex === activeImage ||
                                roi.type === 'SERIES'
                                  ? classes.chip
                                  : classes.hidden
                              }
                              label={roi.class}
                              icon={
                                <VisibilityIcon
                                  style={{ color: 'white' }}
                                  onClick={() => {
                                    roi.data.measurementData.visible = !roi
                                      .data.measurementData.visible
                                  }}
                                />
                              }
                              deleteIcon={
                                <CloseIcon style={{ color: 'white' }} />
                              }
                              onClick={() => {
                                highlightClassification(roi)
                              }}
                              onDelete={() => {
                                removeClassification(roi)
                              }}
                            />
                          ))}
                        </div>
                      </CornerstoneViewport>
                        )}
                      </LoadingOverlay>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2} style={{ backgroundColor: '#23445A' }}>
                <Paper
                  square
                  className={classes.sidebarConfirmationHeader}
                  elevation={0}
                >

                </Paper>
                <Grid item xs={12} className={classes.confirmationContainer}>
                  <ViewportSidebarConfirmation
                    type={category.replace('-class', '')}
                    nextStudyDetails={nextStudyDetails}
                    results={roiArray}
                    studyId={studyId}
                    setIsSubmit={setIsSubmit}
                    cornerstoneElement={cornerstoneElement}
                    campaignType={campaignType}
                    isSubmit={isSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Hotkeys>
  )
}

export default Viewport
