import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    toolsMenuButton: {
        border: '0px solid!important',
        color: '#718796',
        fontSize: '1.5rem',
    },
    toolsMenuButtonGroup: {
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    toolsMenuContainer: {
        borderRadius: 0,
        border: '0px',
        backgroundColor: '#FFFFFF',
        height: '50px',
    },
    sidebarContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: '#F4F7FE',
    },
    sidebarHeader: {
        justifyContent: 'left',
        paddingLeft: '20px',
        paddingTop: '10px',
        borderRadius: 0,
        borderRight: '0',
        backgroundColor: '#FFFFFF',
        color: '#23445ADE',
        height: '50px',
    },
    sidebarHeaderText: {
        lineHeight: '2',
        fontWeight: '500',
        color: '#718796',
    },
    confirmationContainer: {
        borderRadius: 0,
        border: '0px',
        height: '100%',
    },
     sidebarConfirmationHeader: {
        justifyContent: 'left',
        // paddingLeft: '15px',
        paddingTop: '5px',
        borderRadius: 0,
        borderRight: '0',
        backgroundColor: '#FFFFFF',
        color: 'white',
        height: '50px',
    },
    sidebarConfirmationHeaderText: {
        lineHeight: '2.5',
        fontWeight: '600',
    },
    sidebarConfirmationHeaderAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: 'white',
        color: '#23445A',
    },
    sidebarConfirmationHeaderAvatarRoot: {
        display: 'flex',
        '& > *': {
          margin: '4px',
        },
    },
    alert: {
        borderRadius: '0px',
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        padding: '1px 16px',
        width: '100%',
        top: 0,
        opacity: '0.85',
    },
    alertLabel: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        fontWeight: '800',
        marginRight: '3vh',
    },
    alertClose: {
        backgroundColor: 'white', 
        borderColor: 'white',
        // padding: '0.1rem',
        "&:hover, &:focus": {
          backgroundColor: 'white',
          borderColor: 'white',
        },
    },
    stepCard: {
        marginRight: '5px',
        color: 'white'
    },
    stepCardText: {
        paddingTop: '10px',
        fontWeight: '500',
        fontStyle:'italic',
        fontSize:'1.1rem',
    },
    chipContainer: {
        minHeight: '30px',
    },
    chip: {
        marginRight: '5px',
    },
    hidden: {
        display:'none'
    },
}));
