import React, {useState} from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import {Grid, Paper} from '@material-ui/core';

import { studyService } from '../../services/studies';
// styles
import useStyles from "./styles";

// import moment from 'moment';
import './UsersTable.css';
import MaterialTable from 'material-table';

export const Validate = props => {

  const classes = useStyles();

  let history = useHistory()

  const [modality, setModality] = useState('CR');
  const [modifiedRows, setModifiedRows] = useState([]);
  const [usersList, setUsersList] = useState({});
  const [doctor, setDoctor] = useState('');

  const handleChange = (event) => {
    setDoctor(event.target.value);
  };

  const parseTableFormat = (response) => {
    var feedbackArray = []
    response.map(item => {
      item.feedback.map(function(f) {
        feedbackArray.push(
          {
            'userId':f.user_name,
            'examId':item.id,
            'pathology':f.pathology,
            'date':f.created,
            'feedbackId':f.id,
            'examPath':item.exam_image_path,
            'prediction': item.prediction.reduce(function(r, e){ 
              if(e.possibility != 'none'){r.push(`${e['class_']}(${Math.floor(e['score'])}%), `)}; 
              return r
            }, []),
          }
        )
      })
    });
    return feedbackArray
  };

  const tableRef = React.createRef();
  return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper square style={{ height: '100%' }}>
            <MaterialTable style={{ paddingLeft: "10px" }}
              localization={{header: {actions: ''}}}
              title={''}
              tableRef={tableRef}
              components={{
                Toolbar: (props) => (
                  <div>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">Médico</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={doctor}
                        onChange={handleChange}
                        label="Médico"
                      >
                        <MenuItem value="" onClick={() => tableRef.current.onQueryChange()}>
                          <em>None</em>
                        </MenuItem>
                        {
                          Object.entries(usersList).map( ([key, value]) => 
                            <MenuItem value={key} onClick={() => tableRef.current.onQueryChange()} >{value}</MenuItem> 
                        )
                        }
                      </Select>
                    </FormControl>
                  </div>
                )
              }}
              columns={[
                { title: 'Identificação do Médico', 
                  field: 'userId', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, 
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'ID do Exame', 
                  field: 'examId', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, 
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'Label', 
                  field: 'pathology', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, 
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'prediction', 
                  field: 'prediction', 
                  headerStyle: {textAlign:'left', fontSize:'12px'}, defaultSort: 'desc',
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
                { title: 'Data do Feedback', 
                  field: 'date',
                  render: rowData => moment(rowData.date).format('DD/MM/YYYY'),
                  headerStyle: {textAlign:'left', fontSize:'12px'},
                  cellStyle: {textAlign:'left', fontSize:'12px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}, 
                },
              ]} 
              data={query =>
                new Promise((resolve, reject) => {
                  studyService.fetchValidateInfo(query.page + 1, query.pageSize, modality, doctor).then((result) => {
                    setUsersList(result.users)
                    resolve({
                      data: parseTableFormat(result.data),
                      page: result.page - 1,
                      totalCount: result.total,
                    })
                  }).catch(err => {
                    console.log(err)
                    history.push('/app/dashboard')
                  })
                })
              }
              options={{
                search: false,
                sorting: false,
                pageSize: 30,
                padding: 'dense',
                // toolbar: false,
                paginationType: 'stepped',
                paginationPosition: 'top',
                rowStyle: rowData => ({
                  backgroundColor: (modifiedRows.includes(rowData.feedbackId)) ? '#FFB2B2' : '#FFF'
                })
              }}
              actions={[
                {
                  icon: () => <Check style={{color: 'green'}} />,
                  tooltip: 'Certo',
                  onClick: (event, rowData) => { 
                    modifiedRows.indexOf(rowData.feedbackId) === -1 && setModifiedRows(modifiedRows => [...modifiedRows, rowData.feedbackId])
                    studyService.postValidation({'id': rowData.feedbackId, 'isApproved':true})
                  }
                },
                {
                  icon: () => <Clear style={{color: 'red'}}/>,
                  tooltip: 'Errado',
                  onClick: (event, rowData) => {
                    modifiedRows.indexOf(rowData.feedbackId) === -1 && setModifiedRows(modifiedRows => [...modifiedRows, rowData.feedbackId])
                    studyService.postValidation({'id': rowData.feedbackId, 'isApproved':false})
                  }
                },
              ]}
              onRowClick={(event, rowData, togglePanel) => {togglePanel();}}
              detailPanel={[
                {
                  render: rowData => {
                    return (
                      <div className={classes.imageContainer}>
                        <img style={{ height: '80vh' }} src={rowData.examPath} loading="lazy"/>
                      </div>
                    )
                  },
                },
              ]}
            />
          </Paper>
        </Grid>
    </Grid>
  );
}
export default Validate;
