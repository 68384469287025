import React from 'react'

import RotateRightIcon from '@material-ui/icons/RotateRight'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import LoupeIcon from '@material-ui/icons/Loupe'
import InvertColorsIcon from '@material-ui/icons/InvertColors'
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff'

export const TRANSLATE_CLASSIFICATION_CLASS = {
  effusion: 'Derrame pleural',
  mass: 'Massa',
  cardiomegaly: 'Cardiomegalia',
  bacterial_pneumonia: 'Pneumonia bacteriana',
  opacity: 'Opacidade',
  pneumothorax: 'Pneumotórax',
  edema: 'Edema',
  pneumonia: 'Pneumonia',
  ateromatose: 'Ateromatose',
  covid: 'Covid',
  covid19: 'Covid',
  nofinding: 'Sem achados',
  finding: 'Possível achado',
  normal: 'Sem achados',
  head_critical: 'Critico',
  brainbleed: 'Hemorragia cerebral',
  hernia: 'Hernia',
  fratura: 'Fratura',
  critico: 'Critico',
  somefinding: 'Achado detectado',
  cronico: 'Cronico',
  calcificacao: "Calcificação",
  birads_12: "Birads 12",
  birads_345: "Anormalidade",
  densidade: "Densidade",
  nodulo: "Nódulo",
  birads_1_2: "Birads 1 2",
  birads_3_4_5: "Anormalidade",
  calcification: "Calcificação",
  nodule: "Nódulo",
  high_density: "Densidade alta",
}

export const STATUS = {
  skip: 'SKIP',
  validated: 'VALIDATED'
}

export const TOOLS_CORNERSTONE = [
  {
    name: 'Rotate',
    modeOptions: { mouseButtonMask: 2 },
    props: {
      configuration: {
        roundAngles: false,
        flipHorizontal: false,
        flipVertical: false,
        rotateScale: 0.6
      },
      defaultStrategy: 'vertical'
    }
  },
  {
    name: 'Pan',
    modeOptions: { mouseButtonMask: 2 }
  },
  {
    name: 'Magnify',
    modeOptions: { mouseButtonMask: 2 }
  },
  {
    name: 'Wwwc',
    modeOptions: { mouseButtonMask: 2 }
  },
  {
    name: 'StackScrollMouseWheel',
    mode: 'active'
  },
  {
    name: 'Zoom',
    mode: 'active',
    modeOptions: { mouseButtonMask: 3 }
  },
  { name: 'PanMultiTouch', mode: 'active' },
  { name: 'ZoomTouchPinch', mode: 'active' },
  { name: 'StackScrollMultiTouch', mode: 'active' },
  {
    name: 'FreehandRoi',
    mode: 'active',
    modeOptions: { mouseButtonMask: 1 },
    props: {
      configuration: {
        activeHandleRadius: 4,
        spacing: 1,
        renderDashed: true
      }
    }
  },
  {
    name: 'RectangleRoi',
    mode: 'active',
    modeOptions: { mouseButtonMask: 1 }
  }
]

export const TOOLS_BAR_DATA = [
  {
    name: 'Girar',
    icon: ({ size, color }) => <RotateRightIcon size={size} color={color} />,
    active: false,
    tool: 'Rotate'
  },
  {
    name: 'Move',
    icon: ({ size, color }) => <OpenWithIcon size={size} color={color} />,
    active: false,
    tool: 'Pan'
  },
  {
    name: 'Contraste',
    icon: ({ size, color }) => <InvertColorsIcon size={size} color={color} />,
    active: false,
    tool: 'Wwwc'
  },
  {
    name: 'Zoom',
    icon: ({ size, color }) => <LoupeIcon size={size} color={color} />,
    active: false,
    tool: 'Zoom'
  },
  {
    name: 'Inverter',
    icon: ({ size, color }) => (
      <InvertColorsOffIcon size={size} color={color} />
    ),
    active: false,
    tool: 'Inverter'
  }
]

export const VOI_PRESETS = [
  {
    name: 'CHEST',
    label: 'CHEST',
    windowWidth: 1600,
    windowCenter: -600
  },
  {
    name: 'SKULL',
    label: 'BRAIN',
    windowWidth: 80,
    windowCenter: 40
  },
  {
    name: 'BONE',
    label: 'BONE',
    windowWidth: 2800,
    windowCenter: 600
  },
  {
    name: 'BODY',
    label: 'BODY',
    windowWidth: 350,
    windowCenter: 50
  }
]

export const SELECT_MARKER = [
  { label: 'Normal', color: '#e74c3c', type: 'NoFinding' },
  { label: 'Imagem inadequada', color: '#d35400', type: 'BadQuality' },
  { label: 'Anquilose', color: '#6b3ddf', type: 'FreehandRoi' },
  { label: 'Aparelhos', color: '#1d6659', type: 'FreehandRoi' },
  { label: 'Artropatia', color: '#46b535', type: 'FreehandRoi' },
  { label: 'Calcifica\u00e7\u00e3o', color: '#2e8bc0', type: 'FreehandRoi' },
  { label: 'Deformidade', color: '#226035', type: 'FreehandRoi' },
  { label: 'Edema', color: '#883a47', type: 'FreehandRoi' },
  { label: 'Enfisema', color: '#df9b3d', type: 'FreehandRoi' },
  { label: 'Fratura', color: '#5436cc', type: 'FreehandRoi' },
  { label: 'Fratura consolidada', color: '#5436cc', type: 'FreehandRoi' },
  { label: 'Lise', color: '#5a4421', type: 'FreehandRoi' },
  {
    label: 'Luxa\u00e7\u00e3o/Desalinhamento',
    color: '#3d53df',
    type: 'FreehandRoi'
  },
  { label: 'Pseudoartrose', color: '#df3da7', type: 'FreehandRoi' },
  { label: 'Tumor', color: '#52df3d', type: 'FreehandRoi' }
]
