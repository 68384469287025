import React from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  Tooltip,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import copy from 'copy-to-clipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useStyles } from './styles'
import { handleFinishCampaing } from '../../controller'

export const Header = ({
  validationContent,
  project,
  handleSubmit,
  disabledFinish,
  setLoading,
  history,
  total,
  normalButtonDisabled,
  setGetHandle,
  setWindowingDefaultActive,
  predictions
}) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [openDialogFinish, setOpenDialogFinish] = React.useState(false)
  const [openFinishExam, setOpenFinishExam] = React.useState(false)
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false)
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => {
            setOpen(false)
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          ID Copiado com Sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFinishExam}
        autoHideDuration={6000}
        onClose={() => {
          setOpenFinishExam(false)
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => {
            setOpenFinishExam(false)
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          Avaliação encerrada com sucesso!
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialogFinish}
        onClose={() => setOpenDialogFinish(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Você está prestes a encerrar a validação, tem certeza que deseja fazer
          isso?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialogFinish(false)}>Cancelar</Button>
          <Button
            onClick={async () => {
              setOpenDialogFinish(false)
              setLoading(true)
              await handleSubmit()
              await handleFinishCampaing({ id: project.campaign_id })
              setOpenFinishExam(true)
              history.push('/app/dashboard')
              setOpenFinishExam(false)
            }}
            autoFocus
          >
            confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="div"
        display="flex"
        gap="3px"
        alignItems="center"
        justifyContent="left"
        paddingLeft="16px"
        paddingRight="16px"
        paddingTop="20px"
      >
        <Typography fontSize="16px" variant="subtitle2" color="#00C1FA">
          ID:
        </Typography>
        <Typography
          fontSize="16px"
          color="#021439"
          variant="body1"
          style={{
            flex: '1'
          }}
        >
          {validationContent.study_instance_uid}
        </Typography>
        <Tooltip title="Copiar ID">
          <FileCopyIcon
            onClick={() => {
              copy(validationContent.study_instance_uid)
              setOpen(true)
            }}
            style={{
              cursor: 'pointer',
              width: '20px'
            }}
          />
        </Tooltip>
      </Box>
      <Box
        component="header"
        display="flex"
        py={2}
        px={2}
        gap={2}
        borderBottom="1px solid #E6E6E6"
        mb={2}
      >
        <Box
          flex="1"
          display="flex"
          sx={{
            width: '100%'
          }}
          justifyContent="left"
          gap={3}
        >
          <Box
            component="div"
            display="flex"
            gap="3px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography fontSize="16px" variant="subtitle2" color="#00C1FA">
              Idade:
            </Typography>
            <Typography
              fontSize="16px"
              color="#021439"
              variant="body1"
              whiteSpace="nowrap"
            >
              {validationContent.dob
                ? `${moment().diff(validationContent.dob, 'years')} anos`
                : 'N/A'}
            </Typography>
          </Box>
          <Box
            component="div"
            display="flex"
            gap="3px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography fontSize="16px" variant="subtitle2" color="#00C1FA">
              Sexo:
            </Typography>
            <Typography fontSize="16px" color="#021439" variant="body1">
              {new Map([
                ['M', 'Masculino'],
                ['F', 'Feminino']
              ]).get(validationContent.sex) || 'N/A'}
            </Typography>
          </Box>
        </Box>
        <button
          disabled={normalButtonDisabled}
          className={classes.button}
          variant="contained"
          onClick={async () => {
            predictions.map(item =>
              item.prediction_class == 'nofinding'
                ? (item.is_present = true)
                : null
            )
            setLoading(true)
            if (total.total - 1 === total.total_validated){
              await handleSubmit()
              await handleFinishCampaing({ id: project.campaign_id })
              setOpenFinishExam(true)
              history.push('/app/dashboard')
              setOpenFinishExam(false)
            }
            else{
              setGetHandle('next')
              setWindowingDefaultActive(null)
            }
          }}
        >
          Exame Normal
        </button>
        <button
          className={classes.button}
          disabled={disabledFinish}
          variant="contained"
          onClick={() => setOpenDialogFinish(true)}
        >
          Encerrar Validação
        </button>
      </Box>
    </>
  )
}
