import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

// components
import Layout from './Layout'

// pages
import Error from '../pages/error'
import Login from '../pages/login'

import Dashboard from '../pages/dashboard'
import Study from '../pages/study'
import Validate from '../pages/validate'
import Campaign from '../pages/campaign'

// context
import { userStore } from '../context/UserContext'
import { ModalValidation } from '../pages/ModalValidation'
import { NLPValidation } from '../pages/NLPValidation'
import { GDPR } from '../pages/GDPR'
import Ner from './Ner/Ner'

function App(props) {
  // global
  const isAuthenticated = userStore(state => state.isAuthenticated)

  return (
    <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <PublicRoute path="/login">
          <Login />
        </PublicRoute>
        <Route path="/app/validação-de-modelos" component={ModalValidation} />
        <Route path="/app/validação-de-modelos-nlp" component={NLPValidation} />
        <Route path="/app/gdpr" component={GDPR} />
        <Route
          path="/app/ner/:id"
          component={() => (
            <Ner
              sidebar={false}
              style={{ minWidth: '100%', height: '512px', flex: '1' }}
            />
          )}
        />
        <PrivateRoute path="/app/:path+" exact>
          <Layout>
            <Route path="/app/campaign" component={Campaign} />
            <Route path="/app/validate" component={Validate} />
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/:category/:id" component={Study} />
          </Layout>
        </PrivateRoute>
        <Route component={Error} />
    </Switch>
  )

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/app',
                state: {
                  from: props.location
                }
              }}
            />
          )
        }
      />
    )
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location
                }
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    )
  }
}

export default withRouter(App)
