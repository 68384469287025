import React from 'react'
import { useState } from 'react'

export const ModalTextArea = ({
  setModalPresence,
  commentsState,
  id,
  ...props
}) => {
  const [comments, setComments] = commentsState
  const [value, setValue] = useState(() => {
    const hasDefaultValue = comments.filter(item => item.id === id)
    if (hasDefaultValue.length > 0 && hasDefaultValue[0]?.comment) {
      return hasDefaultValue[0].comment
    }

    return ''
  })
  return (
    <div
      className="bg-[#24344A] w-[290px] h-[150px] flex flex-col absolute z-999"
      style={{
        opacity: props.open ? 1 : 0,
        top: `${props.position.y}px`,
        left: `${props.position.x}px`,
        zIndex: 999
      }}
    >
      <textarea
        className="text-[#fff] bg-[#24344A] flex-grow font-epilogue font-normal p-2 outline-0"
        placeholder="Digite sua justificativa..."
        onChange={event => {
          setValue(event.target.value)
        }}
        value={value}
      />
      <div className="w-full border-t p-3 flex justify-end gap-3">
        <button
          className="text-[#fff] font-epilogue font-normal"
          onClick={() => {
            setValue('')
            setModalPresence({
              open: false,
              position: {
                x: 0,
                y: 0
              },
              id: null
            })
          }}
        >
          Cancelar
        </button>
        <button
          className="text-[#fff] font-epilogue font-normal border border-[#012297] px-3"
          onClick={() => {
            setValue('')
            setModalPresence({
              open: false,
              position: {
                x: 0,
                y: 0
              },
              id: null
            })
            const currentItem = comments.filter(item => item.id === id)
            if (currentItem.length > 0) {
              setComments(prevState => {
                return [
                  ...prevState.filter(item => item.id !== currentItem[0].id),
                  {
                    ...currentItem[0],
                    comment: value
                  }
                ]
              })
            }
          }}
        >
          Salvar
        </button>
      </div>
    </div>
  )
}
