import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Radio,
  Typography,
  RadioGroup,
  CardContent,
} from '@mui/material'
import 'react-tooltip/dist/react-tooltip.css'
import { v4 as uuidv4 } from 'uuid'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { userStore } from "../../context/UserContext";
import logo from './assets/logo.svg'
import writeIcon from './assets/write.svg'
import { Header } from './components/Header'
import Annotator from '../../components/Annotator/Annotator'
import {
  TRANSLATE_CLASSIFICATION_CLASS,
  STATUS
} from './contants.jsx'
import { useStyles } from './styles'
import {
  handleChangeData,
  handleNextExam,
  getTotalExams,
  handleSkipcampaing,
  handleSubmit,
  parseResultsNlp
} from './controller'
import { Loading } from '../../components/Loading'
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { ModalTextArea } from './components/ModalTextArea'
import { studyService } from '../../services/studies';
export const NLPValidation = () => {
  const {
    state: { project }
  } = useLocation()
  const classes = useStyles()
  const [modalPresence, setModalPresence] = useState({
    open: false,
    position: [],
    id: null
  })
  const [correctLocalComments, setCorrectLocalComments] = useState([])
  const [loading, setLoading] = useState(true)
  const [predictions, setPredictions] = useState([])
  const [activePathologies, setActivePathologies] = useState([])
  const [nextLoading, setNextLoading] = useState(true)
  const [getHandle, setGetHandle] = useState(null)
  const [resultsArray, setResultsArray] = useState([])
  const [validationContent, setValidationContent] = useState(null)
  const [selectedPathology, setSelectedPathology] = useState({
    name: '',
    phrase: []
  })
  const history = useHistory()
  const [roiArray, setRoiArray] = useState([])
  const [total, setTotal] = useState({
    total: 0,
    total_validated: 0
  })
  const [windowingDefaultActive, setWindowingDefaultActive] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState([])
  const [contextTags, setContextTags] = useState([])
  const [tag, setTag] = useState(null)
  const [selectedOption, setSelectedOption] = useState('')
  const [studyId, setStudyId] = useState('');
  const [activePart, setActivePart] = useState('')
  const [showPathology, setShowPathology] = useState(false)
  
  const [studyDetails, setStudyDetails] = useState('');
  const user = userStore.getState().user

  function countTokens(text, initialPosition, finalPosition, word) {
    const tokens = text.split(/\s+/);
    let count = 0;
    let firstToken = -1;
    let lastToken = -1;
  
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      const beginToken = count;
      const endToken = count + token.length;

      if ((token.length === 1 && (endToken >= initialPosition && beginToken <= finalPosition) && word === true) ||
          (endToken > initialPosition && beginToken < finalPosition)) {
        if (firstToken === -1) {
          firstToken = i;
        }
        lastToken = i;
      }
      count += token.length + 1;
    }
    lastToken += 1


    return { firstToken, lastToken};
  }

function breakPhrase(phrases, words) {
  let newPhrases = []

  phrases.sort((a, b) => a[1] - b[1])

    phrases.forEach((phrase) => {
      let wordsToBreak = []

      let [textPhrase, beginPhrase, endPhrase] = phrase

      words.forEach((word) => {
        let [textWord, beginWord, endWord] = word
        
        if (((beginPhrase <= beginWord) && (beginWord <= endPhrase)) && ((beginPhrase <= endWord) && (endWord <= endPhrase))){
          wordsToBreak = [...wordsToBreak, word]
        }
      })

      let currentPhrase = phrase
      let spliced_phrase_1 = ''
      let spliced_phrase_2 = ''

      wordsToBreak.sort((a, b) => a[1] - b[1])

      for (let i = 0; i < wordsToBreak.length; i++) {
        let [textPhrase, beginPhrase, endPhrase] = currentPhrase
        let [textWord, beginWord, endWord] = wordsToBreak[i]

        if ((beginPhrase <= beginWord <= endPhrase) && (beginPhrase <= endWord <= endPhrase)){
          let start = textPhrase.indexOf(textWord)

          if (start === -1){
            continue
          }

          let textPhrase1 = textPhrase.slice(0, start)
          spliced_phrase_1 = [textPhrase1, beginPhrase, beginWord]
  
          let textPhrase2 = textPhrase.slice(start + textWord.length, textPhrase.length)
          spliced_phrase_2 = [textPhrase2, beginWord + textWord.length, endPhrase]

          if (spliced_phrase_1[0].trim() !== ''){
            newPhrases = [...newPhrases, spliced_phrase_1]
          }
            
          currentPhrase = spliced_phrase_2
        }
      }

      if (spliced_phrase_2[0].trim() !== ''){
        newPhrases = [...newPhrases, spliced_phrase_2]
      }
    })

    return newPhrases
  }

  useEffect(() => {
    if(studyId === ''){
      studyService.fetchNextExamId(
        project.modality,
        project.campaign_id
      )
        .then(response => {
          if(response === undefined) {
            history.push('/app/dashboard')
          }
          setActivePart(response[0].data.body_part)
          setStudyId(response[0].data.id)
          setStudyDetails(response[0])
          handleChangeData({
            data: response[0].data,
            setValidationContent,
            setPredictions,
            setLoading
          })
          setIsLoaded(true)
          setNextLoading(false)
        }).catch(err => {
          history.push('/app/dashboard')
        })
    }
  }, [studyId])

  const nextButtonDisabled = ![
    roiArray.filter(item => item.pathology !== '').length === 0,
    !predictions
      .map(item => item.is_present)
      .includes(true),
      !(state.length > 0 && showPathology === false)
  ].includes(false)

  const normalButtonDisabled = [
    roiArray.filter(item => item.pathology !== '').length === 0,
    !predictions
      .map(item => item.is_present)
      .includes(true),
    !(state.length > 0 && showPathology === false)
  ].includes(false)

  const [openDialogSkip, setOpenDialogSkip] = useState(false)

  useEffect(() => {
    (async () => {
      const total = await getTotalExams({
        id: project.campaign_id,
        type: project.modality
      })
      setTotal(total)
    })()
  }, [history, project])

  const addClassification = pathology => {
    if (resultsArray.some(result => result.classification === pathology)) return
    let newResultsArray = pathology.map(annotation => {
      return {
        id: uuidv4(),
        classification: annotation.tag,
        doc_details_id: studyId,
        pathology_info_id: project.campaign_id,
        body_part: activePart,
        ner_result: annotation
      }
    })
    setResultsArray(newResultsArray)
  }

  useEffect(() => {
    if (getHandle === STATUS.skip && nextLoading === false) {
      handleSkipcampaing({
        setOpenDialogSkip,
        setLoading,
        predictions,
        project,
        studyDetails,
        setCorrectLocalComments
      })
      setGetHandle(null)
    }
    if (getHandle === 'next' && nextLoading === false) {
      handleNextExam({
        setLoading,
        predictions,
        project,
        studyDetails,
        correctLocalComments,
      })
      let parsedResults = parseResultsNlp(resultsArray, 'SUBMIT')
      studyService.postResults(parsedResults, 'nlp').then(response => {
        if (response.status === 200) {
          setState([])
          setContextTags(null)
          setTag([])
        }
      })
      setGetHandle(null)
    }
  }, [
    nextLoading,
    getHandle,
    predictions,
    roiArray,
    project,
    correctLocalComments
  ])

  return (
    <Container
      disableGutters
      display="flex"
      flex="col"
      sx={{
        minHeight: '100vh',
        backgroundColor: '#000'
      }}
      maxWidth="none"
    >
      {loading && <Loading />}
      {modalPresence.open && (
        <ModalTextArea
          {...modalPresence}
          setModalPresence={setModalPresence}
          commentsState={[correctLocalComments, setCorrectLocalComments]}
        />
      )}

      <Dialog
        open={openDialogSkip}
        onClose={() => setOpenDialogSkip(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deseja realmente pular esta avaliação?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDialogSkip(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              setGetHandle(STATUS.skip)
              setLoading(true)
              setWindowingDefaultActive(null)
              setOpenDialogSkip(false)
            }}
            autoFocus
          >
            confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="header"
        p={2}
        bgcolor="#021439"
        display="flex"
        alignItems="center"
        gap="16px"
        sx={{
          height: '83px'
        }}
      >
        <img src={logo} alt="logo" />
        <Typography
          variant="subtitle1"
          color="#fff"
          fontSize="14px"
          fontWeight={400}
        >
          Validação de Modelo NLP {'>'} {project.title}
        </Typography>
      </Box>

      <Grid
        container
        sx={{
          minHeight: 'calc(100vh - 83px)',
          height: '100%'
        }}
      >
        <Grid item xs={7} bgcolor="#fff" display="flex" flexDirection="column">
          <Grid container flex="1">
            <Grid
              item
              bgcolor="#000"
              display="flex"
              flexDirection="column">
              <Box
                display="flex"
                bgcolor="#fff"
                alignItems="center"
                gap="18px"
                sx={{ padding: '10px 15px' }}
                justifyContent="center"
              >
              </Box>
              <Box
                flex="1"
                bgcolor="white"
                display="block"
                alignItems="center"
                justifyContent="center"
                position="relative"
              >

<CardContent>
    {isLoaded && 
    <Typography variant="h5" className={classes.stepCardText} >
      <Annotator
        state={[state, setState]}
        contextTagsState={[contextTags, setContextTags]}
        resultsArray={resultsArray}
        addClassification={addClassification}
        studyDetails={studyDetails}
        tag={[tag, setTag]}
      />
      </Typography>
    }

  </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={5}
          itemspacing={0}
          bgcolor="#F4F7FE"
          display="flex"
          flexDirection="column"
          paddingBottom="20px"
        >
          {validationContent && (
            <Header
              validationContent={validationContent}
              setLoading={setLoading}
              setGetHandle={setGetHandle}
              setWindowingDefaultActive={setWindowingDefaultActive}
              predictions={predictions}
              normalButtonDisabled={normalButtonDisabled}
              total={total}
              handleSubmit={() =>
                handleSubmit({
                  roi_list: roiArray,
                  predictions,
                  modality: project.modality
                })
              }
              history={history}
              project={project}
              disabledFinish={[
                total.total - 1 !== total.total_validated,
                nextButtonDisabled
              ].includes(true)}
            />
          )}

          <Grid container p="18px">
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography
                variant="subtitle2"
                color="#0310C9"
                fontSize="16px"
                fontWeight="700"
                marginBottom="10px"
              >
                Avaliação
              </Typography>
              <Box paddingTop="10px" paddingBottom="10px">
                <table>
                  <thead>
                    <tr>
                      <th
                        className={classes.th}
                        style={{
                          paddingLeft: 0
                        }}
                      >
                        Patologias<br/> Existentes
                      </th>
                      <th className={classes.th}>
                        {/* Porcentagem Encontrada */}
                        </th>
                      <th className={classes.th}>Exibir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validationContent &&
                      validationContent?.prediction.map((item, index) =>
                        TRANSLATE_CLASSIFICATION_CLASS[item.alert] !==
                        'Sem achados' ? (
                          <tr key={index}>
                            <td
                              className={classes.td}
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              {TRANSLATE_CLASSIFICATION_CLASS[item.alert]}
                            </td>
                            <td className={classes.td}>
                              {/* {Math.round(item.score)}% */}
                            </td>
                            <td className={classes.td}>
                              <Radio
                                size="small"
                                checked={
                                  selectedPathology.name === item.alert
                                }
                                onClick={event => {
                                  if (
                                    selectedPathology.name === item.alert
                                  ) {
                                    setShowPathology(false)
                                    setSelectedPathology({
                                      name: '',
                                      phrase: ''
                                    })
                                    setState([])
                                    setTag(null)
                                    setContextTags([])
                                  } else if (item.phrases) {
                                    setShowPathology(true)
                                    setSelectedPathology({
                                      name: event.target.value,
                                      phrase: item.phrases[0].phrase.map(
                                        e => e
                                      )
                                    })
                                    // Code to highlight text
                                    let state = []
                                    if (item.phrases[0] && item.phrases[0].words) {
                                      item.phrases[0].words.forEach((words) => {
                                        let contadores = countTokens(studyDetails.data.text, words[1], words[2], true)
                                        let word = {
                                          "key": contadores["firstToken"] + "-" + contadores["lastToken"],
                                          "start": contadores["firstToken"],
                                          "end":contadores["lastToken"],
                                          "tokens": [words[0]],
                                          "tag": "",
                                          "color": "bg-red-200",
                                          "mark": true,
                                          "content": words[0]
                                        }
                                        state = [...state, word]                      
                                      });
                                    }
                                    let phrases = breakPhrase(item.phrases[0].phrase, item.phrases[0].words)
                                    phrases.forEach((phrase) => {
                                        let contadores = countTokens(studyDetails.data.text, phrase[1], phrase[2], false)
                                        let word = {
                                          "key": contadores["firstToken"] + "-" + contadores["lastToken"],
                                          "start": contadores["firstToken"],
                                          "end":contadores["lastToken"],
                                          "tokens": [phrase[0]],
                                          "tag": "",
                                          "color": "bg-yellow-200",
                                          "mark": true,
                                          "content": phrase[0]
                                        }
                                        state = [...state, word]  
                                    });
                                    setSelectedOption('Patologias Extras')
                                    setState(state)
                                    setTag(null)
                                    setContextTags([])
                                  }
                                }}
                                disabled={
                                  !item.score ||
                                  !['high', 'certain', 'probable'].includes(
                                    item.possibility
                                  ) ||
                                  item.alert === 'nofinding' ||
                                  item.alert === 'somefinding'
                                }
                                value={item.alert}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': item.alert }}
                              />
                            </td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </table>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography
                variant="subtitle2"
                color="#0310C9"
                fontSize="16px"
                fontWeight="700"
                marginBottom="10px"
              >
                Opinião Médica
              </Typography>
              <Box
                border="1px solid #00C1FA"
                marginTop="10px"
                paddingBottom="10px"
              >
                <table>
                  <thead>
                    <tr>
                      <th
                        className={classes.th}
                        style={{
                          color: '#00C1FA',
                          paddingRight:'20px'
                        }}
                      >
                        Patologia<br/> Presente
                      </th>
                      <th
                        className={classes.th}
                        style={{
                          color: '#00C1FA'
                        }}
                      >
                        Local Correto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {predictions.length > 0 &&
                      validationContent &&
                      validationContent?.prediction.map((item, index) =>
                        TRANSLATE_CLASSIFICATION_CLASS[item.alert] !=
                        'Sem achados' ? (
                          <tr key={index}
                          style={{
                            paddingTop:0,
                          }}>
                            <td className={classes.td}>
                              <Checkbox
                                size="small"
                                name={`correct_location_${item.alert}`}
                                checked={Boolean(
                                  predictions.find(
                                    prediction =>
                                      prediction.prediction_id === item.id
                                  )?.is_present
                                )}
                                onClick={event => {
                                  const oldPredictions = predictions.filter(
                                    prediction =>
                                      prediction.prediction_id !== item.id
                                  )
                                  const currentPrediction = predictions
                                    .filter(
                                      prediction =>
                                        prediction.prediction_id === item.id
                                    )
                                    .map(prediction => ({
                                      ...prediction,
                                      is_present: event.target.checked
                                    }))
                                  setPredictions([
                                    ...oldPredictions,
                                    ...currentPrediction
                                  ])
                                  if (event.target.checked){
                                  setActivePathologies([...activePathologies, item.alert]
                                )
                                  }
                                  else{
                                    const newArray = activePathologies.filter(pathology => pathology !== item.alert)
                                    setActivePathologies(newArray)
                                  }
                                }}
                              />
                            </td>
                            <td className={classes.td} style={{paddingLeft:'30px'}}>
                              <RadioGroup
                                aria-labelledby={`presence_${item.alert}`}
                                name={`presence_${item.alert}`}
                                row
                              >
                                <Box display="flex">
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio size="small"/>}
                                    label="Sim"
                                    disabled={
                                      !item.score ||
                                      !['high', 'certain', 'probable'].includes(
                                        item.possibility
                                      ) ||
                                      item.alert === 'nofinding' ||
                                      item.alert === 'somefinding' || nextButtonDisabled || !activePathologies.includes(item.alert)
                                    }
                                    onClick={event => {                    
                                      predictions.map(prediction => {
                                        if (prediction.prediction_id == item.id){
                                          prediction.is_local_correct = event.target.checked
                                        }
                                      }
                                      )
                                      setPredictions(predictions)
                                      setModalPresence({
                                        open: false,
                                        position: {
                                          x: 0,
                                          y: 0
                                        },
                                        id: null
                                      })
                                    }}
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio size="small"/>}
                                    label="Não"
                                    style={{
                                      ...(correctLocalComments.find(
                                        e => e.id === item.id
                                      )?.comment && {
                                        textDecoration: 'underline',
                                        color: '#1A73E8'
                                      })
                                    }}
                                    onClick={event => {
                                      predictions.map(prediction => {
                                        if (prediction.prediction_id == item.id){
                                          prediction.is_local_correct = false
                                        }
                                      }
                                      )
                                      const isActive =
                                        !item.score ||
                                        ![
                                          'high',
                                          'certain',
                                          'probable'
                                        ].includes(item.possibility) ||
                                        item.model_class === 'nofinding' ||
                                        item.model_class === 'somefinding'

                                      if (!isActive && !nextButtonDisabled && activePathologies.includes(item.alert)) {
                                        const rect = event?.currentTarget?.getBoundingClientRect()
                                        setModalPresence({
                                          open: true,
                                          position: {
                                            x: rect.left - 269,
                                            y: rect.top - 145
                                          },
                                          id: item.id
                                        })

                                        setCorrectLocalComments(prevState => {
                                          const currentItemExist = prevState.filter(
                                            prevItem => prevItem.id === item.id
                                          )

                                          if (currentItemExist.length > 0) {
                                            return prevState
                                          }

                                          return [...prevState, item]
                                        })
                                      }
                                      setPredictions(predictions)
                                    }}
                                    disabled={
                                      !item.score ||
                                      !['high', 'certain', 'probable'].includes(
                                        item.possibility
                                      ) ||
                                      item.alert === 'nofinding' ||
                                      item.alert === 'somefinding' || nextButtonDisabled || !activePathologies.includes(item.alert)
                                    }
                                  />
                                </Box>
                              </RadioGroup>
                            </td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </table>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: '100%', mt: '30px' }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
            <img src={writeIcon} alt="writeIcon" />
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  renderValue={() => {
                    return selectedOption || 'Patologias Extras'; 
                  }}
                  displayEmpty
                  sx={{ height: '40px', minWidth: '300px' }}
                  autoWidth
                  value={selectedOption}
                  onChange={event => {
                    setSelectedOption(event.target.value.label)
                    setTag({
                        id: event.target.value.value,
                        label: event.target.value.label,
                        shortcut: "",
                        color: event.target.value.color,
                    })
                    setContextTags(prevState => [
                      ...prevState.map(i => ({
                        ...i,
                        active: false
                      })),
                      {
                        id: event.target.value.value,
                        labels: event.target.value.label,
                        active: true
                      }
                    ])
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Patologias Extras</em>
                  </MenuItem>
                  {
                  project.pathology_list.map(item => ({
                      label: item.label,
                      value: item.id,
                      color: item.color
                    })).map(item => {
                      return <MenuItem key={item.value} value={item}>{item.label}</MenuItem>
                    })}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: '100%', mt: '30px' }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="10px"
            >
              <button
                className={classes.button}
                onClick={() => setOpenDialogSkip(true)}
                disabled={total.total - 1 === total.total_validated}
              >
                Pular
              </button>
              <Box>
                <strong>
                  {total.total_validated + 1} de {total.total}
                </strong>
              </Box>
              <button
                className={classes.button}
                onClick={() => {
                  setGetHandle('next')
                  setWindowingDefaultActive(null)
                  setLoading(true)
                }}
                disabled={
                  total.total - 1 === total.total_validated
                    ? true
                    : nextButtonDisabled
                }
              >
                Próximo
              </button>
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            sx={{ maxWidth: '100%', mt: '30px' }}
            padding="10px"
          ></Box>
        </Grid>
      </Grid>
    </Container>
  )
}
