import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '80vw',
        height: '80vh',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));
