import React from "react";
import { Create, InvertColors, Search, DeleteOutline, OpenWith } from '@material-ui/icons';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import AddIcon from '@material-ui/icons/Add';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import EditIcon from '@material-ui/icons/Edit';
export const cornerstoneConfig = {
    classifications: [
        // {id:1, label:'Pneumonia', color: '#6c5ce7', type: 'FREE_ROI', icon:<AddIcon />},
        // {id:2, label:'Edema', color: '#00b894', type: 'BOX', icon:<AspectRatioIcon />},
        // {id:3, label:'Atelectasia', color: '#fdcb6e', icon:<BorderColorIcon />},
        // {id:4, label:'Mass Nodulo', color: '#e17055', icon:<InvertColors />}
    ],
    toolbarTools: [
        {
            name: 'Wwwc',
            modeOptions: { mouseButtonMask: 1 },
            icon:<InvertColors />,
            cursor: 'nesw-resize',
        },
        {
            name: 'Zoom',
            icon:<Search/>,
            cursor: 'zoom-in',
        },
        {
            name: 'Eraser',
            icon:<DeleteOutline/>,
            cursor: 'no-drop',
        },
        {
            name: 'Pan',
            icon:<OpenWith/>,
            cursor: 'move',
        },
        {
            name: 'Rotate',
            icon:<CropRotateIcon/>,
            cursor: 'alias',
        },
        // {
        //     name: 'FreehandRoiSculptor',
        //     icon:<EditIcon/>,
        // },
    ],
    tools: [
        {
            name: 'FreehandRoi',
            mode: 'active',
            modeOptions: { mouseButtonMask: 1},
            props: {
                configuration: { 
                    activeHandleRadius:4,
                    spacing: 1,
                    renderDashed: true,
                }
            },
            icon:<Create fontSize="small" />,
        },
        { 
            name: 'RectangleRoi',
            mode: 'active',
            modeOptions: { mouseButtonMask: 1},
        },
        // { 
        //     name: 'Brush',
        //     mode: 'active',
        //     modeOptions: { mouseButtonMask: 2},
        // },
        // { 
        //     name: 'FreehandRoiSculptor',
        //     mode: 'active',
        //     modeOptions: { mouseButtonMask: 1},
        //     props: {
        //         configuration: { 
        //             minSpacing: 10,
        //             maxSpacing: 20,
        //             hoverCursorFadeDistance: 1,
        //             dragColor: 'transparent',
        //             showCursorOnHover: false,
        //         }
        //     },
        // },
        {
            name: 'Wwwc',
            mode: 'active',
            modeOptions: { mouseButtonMask: 1 },
            icon:<InvertColors fontSize="small" />,
        },
        {
            name: 'Zoom',
            mode: 'active',
            props: {configuration: {invert: false, preventZoomOutsideImage: false, minScale: .1, maxScale: 20.0,}},
            modeOptions: { mouseButtonMask: 2 },
            icon:<Search fontSize="small" />,
        },
        {
            name: 'Eraser',
            mode: 'active',
            modeOptions: { mouseButtonMask: 1 },
            icon:<InvertColors fontSize="small" />,
        },
        {
            name: 'Pan',
            mode: 'active',
            modeOptions: { mouseButtonMask: 3 },
            icon:<OpenWith fontSize="small" />,
        },
        {
            name: 'Rotate',
            mode: 'active',
            modeOptions: { mouseButtonMask: 1 },
            icon:<CropRotateIcon fontSize="small" />,
        },
        // Scroll
        { name: 'StackScrollMouseWheel', mode: 'active' },
        // Touch
        { name: 'PanMultiTouch', mode: 'active' },
        { name: 'ZoomTouchPinch', mode: 'active' },
        { name: 'StackScrollMultiTouch', mode: 'active' },
    ],
    studyId: '',
    imageIds: [],
    series: [
        {
            seriesId: '',
            thumbnail: '',
            description: '',
            imageSet: [
                
            ],
            test: [
                {'id':1, 'value': 'A'}, {'id':2, 'value': 'B'},
            ]
        },
    ]
}
